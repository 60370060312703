import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Locale from "../locales";
const Measure = (props: any) => {
  function getColor(value: number) {
    if (value < 40) {
      return "success";
    } else if (value >= 40 && value < 100) {
      return "primary";
    } else {
       return "error";
    }
  }

  function getProgress(value: number) {
    if (value <= 0) {
      return 0;
    } else if (value >= 100) {
      return 100;
    } else {
      return value
    }
  }

  const progress = getProgress(props.value);
  const color = getColor(props.value);

    return (
    <div style={{position:"relative"}}>
      {(!props.value || props.value <= 0) ? <span></span> :( props.value <= 100)?
          <LinearProgress variant="determinate"  {...props} value={progress} color={color} />:<span></span>
      }
      {(props.value && props.value >= 100) ?
        <Tooltip title={Locale.Measure.tip} placement='top'>
            {/* <InfoIcon style={{fontSize:'14px',position:'absolute',top:'-7px',right:'-14px'}}  /> */}
            <LinearProgress variant="determinate"  {...props} value={progress} color={color} style={{cursor:'pointer'}} />
        </Tooltip>:<span></span>
      }
    </div>
  );
};

export default Measure;
