import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { TableRow as _TableRow, TableCell as _TableCell } from "@mui/material";
import { toast } from "react-toastify";
import { CSVDownload, CSVLink } from "react-csv";
import {
  addRechargingCards,
  getRechargingCards,
  deleteRechargingCards,
} from "../requests";
import { copyToClipboard } from "../utils";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import BackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Container,
  Typography,
  Link as MuiLink,
  Input as _Input,
} from "@mui/material";
import * as layoutStyles from "./styles";
import FormInput from "../components/FormInput";
import FormText from "../components/FormText";
import { styled } from "@mui/material/styles";
import { hasIn } from "lodash";
import { current } from "@reduxjs/toolkit";
export type LoginInput = TypeOf<typeof loginSchema>;

const loginSchema = object({});

const TableRow = styled(_TableRow)(({ theme: any }) => ({
  "&": {
    height: "50px", // 您可以将此值更改为所需的高度
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
  "&": {
    height: "50px", // 您可以将此值更改为所需的高度
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const headers = [
  { label: "ID", key: "id" },
  { label: "Password", key: "password" },
  { label: "BalanceInCents", key: "balance_in_cents" },
  { label: "Status", key: "status" },
  { label: "VendorName", key: "vendor_name" },
];

interface Column {
  id:
    | "id"
    | "password"
    | "balance_in_cents"
    | "vendor_name"
    | "status"
    | "Operator";
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: any) => any;
}

interface Data {
  id: string;
  password: string;
  balance_in_cents: string;
  vendor_name: string;
  status: string;
  Operator?: string;
}

export default function RechargingCard() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [type, setType] = React.useState("add");
  const [title, setTitle] = React.useState("添加");
  const [RowData, setRowData] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [csvData, setCsvData] = React.useState([]);

  const [searchForm, setSearchForm] = React.useState({ id: "", status: "" });

  const searchOnChange = (e: any, key: string) => {
    setSearchForm((val) => {
      return {
        ...val,
        [key]: e.target.value,
      };
    });
  };

  const handleClickOpen = (type: string, rows?: any) => {
    if (type == "add") setForm({ balance_in_cents: "", amount: "" });
    setType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setTitle(type == "add" ? "添加" : "编辑");
  }, [type]);

  const onGetRechargingCards = async (
    page: number,
    rowsPerPage: number,
    filter?: any
  ) => {
    try {
      await getRechargingCards(page, rowsPerPage, {
        onCallBack(res: any) {
          setTotal(res.total_records);

          if (filter) {
            setRowData(
              filter(res.data.map((i: any) => ({ ...i, Operator: "1" })))
            );
          } else {
            setRowData(res.data.map((i: any) => ({ ...i, Operator: "1" })));
          }
        },
      });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const onAddRechargingCards = async (input: any) => {
    const { amount, balance_in_cents } = input;
    try {
      const res: any = await addRechargingCards(
        Number(amount),
        Number(balance_in_cents),
        {
          onCallBack(data: any) {
            console.log("res: ", data);
            onGetRechargingCards(page, rowsPerPage);
            setCsvData(data.rechargingCards);
            toast.success("添加成功", {
              position: "top-right",
            });
            handleClose();
            setTimeout(() => {
              setCsvData([]);
            }, 500);
          },
        }
      );
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const onDeleteRechargingCards = async (input: any) => {
    const { id } = input;
    try {
      const res: any = await deleteRechargingCards(id, {
        onCallBack(data: any) {
          console.log("data: ", data);
          onGetRechargingCards(page, rowsPerPage);
          toast.success("deactivate 成功", {
            position: "top-right",
          });
        },
      });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  React.useEffect(() => {
    onGetRechargingCards(page, rowsPerPage);
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onGetRechargingCards(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onGetRechargingCards(0, +event.target.value);
  };

  const columns: readonly Column[] = [
    {
      id: "id",
      label: "id",
      minWidth: 170,
      format: (row: any) => {
        return (
          <div
            onClick={() => {
              copyToClipboard(row["id"]);
            }}
          >
            {row["id"]}
          </div>
        );
      },
    },
    {
      id: "password",
      label: "password",
      minWidth: 170,
      format: (row: any) => {
        return (
          <div
            onClick={() => {
              copyToClipboard(row["password"]);
            }}
          >
            {row["password"]}
          </div>
        );
      },
    },
    { id: "balance_in_cents", label: "balance_in_cents", minWidth: 120 },
    {
      id: "vendor_name",
      label: "vendor_name",
      minWidth: 120,
    },
    {
      id: "status",
      label: "status",
      minWidth: 120,
    },
    {
      id: "Operator",
      label: "Operator",
      minWidth: 100,
      format: (column: any) => {
        return (
          <React.Fragment>
            <IconButton
              aria-label="delete"
              onClick={() => {
                onDeleteRechargingCards(column);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        );
      },
    },
  ];

  const Input = styled(_Input)`
    background-color: white;
    padding: 0.4rem 0.7rem;
  `;

  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const [form, setForm] = React.useState({
    balance_in_cents: "",
    amount: "",
  });

  const onChange = (e: any, key: string) => {
    setForm((val) => {
      return {
        ...val,
        [key]: e.target.value,
      };
    });
  };
  const submit = () => {
    onAddRechargingCards(form);
  };

  const search = () => {
    onGetRechargingCards(0, 10000, (data: any) => {
      return data.filter(
        (v: any) =>
          v.id.includes(searchForm.id) && v.status.includes(searchForm.status)
      );
    });
  };
  const reset = () => {
    onGetRechargingCards(page, rowsPerPage);
  };

  return (
    <Paper sx={{ width: "98%", overflow: "hidden", margin: "auto" }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          // maxWidth='27rem'
          width="100%"
          sx={{
            ...layoutStyles.formBox,
            p: { xs: "0.5rem 1rem", sm: "1rem 2rem" },
          }}
          style={{ display: "flex" }}
        >
          <div style={{ marginRight: "1rem" }}>
            <FormInput
              name="id"
              label={"ID"}
              type="amount"
              value={searchForm.id}
              onChange={(e: any) => {
                searchOnChange(e, "id");
              }}
              style={{
                backgroundColor: "var(--white)",
                color: "var(--black)",
              }}
            />
          </div>
          <div style={{ marginRight: "1rem" }}>
            <FormInput
              name="status"
              label={"status"}
              type="status"
              value={searchForm.status}
              onChange={(e: any) => {
                searchOnChange(e, "status");
              }}
              style={{
                backgroundColor: "var(--white)",
                color: "var(--black)",
              }}
            />
          </div>
          <div
            style={{
              marginRight: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button onClick={search} style={{ marginRight: "1rem" }}>
              搜索
            </Button>
            <Button onClick={reset} style={{ lineHeight: "1.4375em" }}>
              重置
            </Button>
          </div>
        </Box>
      </FormProvider>
      <>
        {csvData.length > 0 && (
          <CSVDownload
            data={csvData}
            headers={headers}
            filename={"rechargingCard"}
            uFEFF={true}
            // target={"_blank"}
          />
        )}
      </>
      <div style={{ paddingLeft: "20px" }}>
        <IconButton
          aria-label="add"
          onClick={() => {
            window.location.href = "/";
          }}
          title="返回"
        >
          <BackIcon style={{ width: "2rem", height: "2rem" }} />
        </IconButton>
        <IconButton
          aria-label="add"
          onClick={() => {
            handleClickOpen("add");
          }}
          title="新增"
        >
          <AddIcon style={{ width: "2rem", height: "2rem" }} />
        </IconButton>
      </div>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ maxHeight: 20 }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {RowData.map((row: Data) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value: any = row[column?.id];
                    return (
                      <TableCell key={column.id} align={"center"}>
                        <div
                          style={{
                            maxHeight: "80px",
                            overflow: "auto",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {column.format ? column.format(row) : value}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            backgroundColor: "var(--white)",
            color: "var(--black)",
            borderBottom: "1px solid var(--second)",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "var(--white)" }}>
          <DialogContentText id="alert-dialog-description">
            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                // maxWidth='27rem'
                width="100%"
                sx={layoutStyles.formBox}
              >
                <FormInput
                  name="amount"
                  label={"amount"}
                  type="amount"
                  value={form.amount}
                  onChange={(e: any) => {
                    onChange(e, "amount");
                  }}
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--black)",
                  }}
                />
                <FormInput
                  name="balance_in_cents"
                  label={"balance_in_cents"}
                  type="balance_in_cents"
                  value={form.balance_in_cents}
                  onChange={(e: any) => {
                    onChange(e, "balance_in_cents");
                  }}
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--black)",
                  }}
                />
              </Box>
            </FormProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: "var(--white)",
            color: "var(--black)",
            borderTop: "1px solid var(--second)",
          }}
        >
          <Button onClick={handleClose}>取消</Button>
          <Button
            onClick={() => {
              submit();
            }}
            autoFocus
          >
            提交
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
