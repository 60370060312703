import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../components/FormInput';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRegisterUserMutation } from '../redux/api/authApi';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as layoutStyles from './styles'
import Locale from "../locales";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: var(--primary);
  font-weight: 500;

  &:hover {
    background-color: var(--primary);
    transform: translateY(-2px);
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
`;

const registerSchema = object({
  name: string().min(1, '需要填入完整姓名').max(100),
  email: string()
    .min(1, '需要填入邮箱')
    .email('邮箱地址有误'),
  password: string()
    .min(1, '需要填入密码')
    .min(8, '密码长于八个字符')
    .max(32, '密码要短于三十二个字符'),
  passwordConfirm: string().min(1, '请确认你的密码'),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: '密码不匹配',
});

export type RegisterInput = TypeOf<typeof registerSchema>;

const RegisterPage = () => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  // 👇 Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError, data }] =
    useRegisterUserMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
      navigate('/verifyemail');
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    // 👇 Executing the RegisterUser Mutation
    registerUser(values);
  };

  return (
    <Container
      maxWidth={false}
      sx={layoutStyles.container}
    >
      <Box
        sx={layoutStyles.box}
      >
        <Typography
          textAlign='center'
          component='h1'
          sx={layoutStyles.TypographySm}
        >
            {Locale.LayoutInfo.Welcome}
        </Typography>
        <Typography component='h2' sx={{  mb: 2 }}>
          {Locale.LayoutInfo.registerDescription}
        </Typography>

        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            maxWidth='27rem'
            width='100%'
            sx={layoutStyles.formBox}
          >
            <FormInput name='name' label={Locale.LayoutInfo.username} type='input'/>
            <FormInput name='email' label={Locale.LayoutInfo.email} type='email' />
            <FormInput name='password' label={Locale.LayoutInfo.password} type='password' />
            <FormInput
              name='passwordConfirm'
              label={Locale.LayoutInfo.confirmPassword}
              type='password'
            />
            <Typography sx={{ fontSize: '0.9rem', mb: '1rem' }}>
             {Locale.LayoutInfo.haveAccount}
              <LinkItem to='/login'>Login Here</LinkItem>
            </Typography>

            <LoadingButton
              variant='contained'
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type='submit'
              loading={isLoading}
            >
              {Locale.LayoutInfo.register}
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default RegisterPage;
