import * as React from "react";
import { TableRow as _TableRow, TableCell as _TableCell } from "@mui/material";
import { toast } from "react-toastify";
import { rechargeRechargingCards } from "../requests";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Container, Typography, Link as MuiLink } from "@mui/material";
import * as layoutStyles from "./styles";
import FormInput from "../components/FormInput";
import FormText from "../components/FormText";
import { styled } from "@mui/material/styles";
import { hasIn } from "lodash";
import { convertToYuan } from "../utils";

export type LoginInput = TypeOf<typeof loginSchema>;

const loginSchema = object({});

export default function RechargingForm(props: any) {
  const { open, setOpen, form, setForm } = props;
  const [title, setTitle] = React.useState("充值卡充值");
  const handleClose = () => {
    setOpen(false);
  };

  const onRechargeRechargingCards = async (input: any) => {
    const { card_id, password } = input;
    try {
      const res: any = await rechargeRechargingCards(card_id, password, {
        onCallBack(data: any) {
          console.log("data: ", data);
          handleClose();
          toast.success(
            `充值成功。用户：${
              data?.recharged_email
            }，充值金额：${convertToYuan(
              data?.recharge_amount
            )}元，当前账户金额：${convertToYuan(data?.new_balance)}元`,
            {
              position: "top-right",
            }
          );
        },
      });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const onChange = (e: any, key: string) => {
    setForm((val: any) => {
      return {
        ...val,
        [key]: e.target.value,
      };
    });
  };
  const submit = () => {
    onRechargeRechargingCards(form);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          backgroundColor: "var(--white)",
          color: "var(--black)",
          borderBottom: "1px solid var(--second)",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "var(--white)", padding: "0" }}>
        <DialogContentText id="alert-dialog-description">
          <FormProvider {...methods}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              // maxWidth='27rem'
              width="100%"
              sx={layoutStyles.formBox}
            >
              <FormInput
                name="card_id"
                label={"充值卡ID"}
                type="card_id"
                value={form.id}
                onChange={(e) => {
                  onChange(e, "card_id");
                }}
                style={{
                  backgroundColor: "var(--white)",
                  color: "var(--black)",
                }}
              />
              <FormInput
                name="password"
                label={"充值卡密码"}
                type="input"
                value={form.Password}
                onChange={(e) => {
                  onChange(e, "password");
                }}
                style={{
                  backgroundColor: "var(--white)",
                  color: "var(--black)",
                }}
              />
            </Box>
          </FormProvider>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: "var(--white)",
          color: "var(--black)",
          borderTop: "1px solid var(--second)",
        }}
      >
        <Button onClick={handleClose}>取消</Button>
        <Button
          onClick={() => {
            submit();
          }}
          autoFocus
        >
          充值
        </Button>
      </DialogActions>
    </Dialog>
  );
}
