/*
 * @Description: 
 * @Author: Devin
 * @Date: 2023-03-28 11:37:22
 */
import { useEffect} from 'react'
import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userApi } from '../redux/api/userApi';
import FullScreenLoader from './FullScreenLoader';
import { setCookie, getCookie } from "../utils/cookie";
import { Message, SubmitKey, getChatStore,useUserStore, useAccessStore, ChatSession } from "../store";
import { getMe } from '../requests'


const RequireUser = (props:{admin?:string}) => {
  // const [cookies] = useCookies(['logged_in']);
  const location = useLocation();

  const auth = getCookie('mojolicious')

  const accessStore = useAccessStore();

  useEffect(() => {
    getUser()
  }, [])
  
 const getUser = async () => {
    const { data } = await getMe()
    accessStore.updateUser({
      ...data?.user
    })
  }

  if (props.admin == 'admin') {
    const user: any = accessStore.user
    
    return user.role == 'admin'? (
    <Outlet />
    ): (
      <Navigate to='/' state={{ from: location }} replace />
    );
  }

  return auth? (
    <Outlet />
  ): (
    <Navigate to='/login' state={{ from: location }} replace />
  );
  //  : cookies.logged_in && user ? (
  //   <Navigate to='/unauthorized' state={{ from: location }} replace />
  // ) 
};

export default RequireUser;
