/*
 * @Description:
 * @Author: Devin
 * @Date: 2023-04-06 19:43:24
 */

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { queryMeta } from "../utils";

export interface UserStore {
  user: object;
  isFirstLogin: boolean;
  tip: string;
  updateUser: (user: object) => void;
  setIsFirstLogin: (isFirstLogin: boolean) => void;
  updateTip: (tip: string) => void;
}

export const USER_KEY = "user-info";

export const useUserStore = create<UserStore>()(
  persist(
    (set, get) => ({
      user: {},
      isFirstLogin: false,
      tip: "",
      updateUser(user: object) {
        set((state) => ({ user }));
      },
      setIsFirstLogin(isFirstLogin: boolean) {
        set((state) => ({ isFirstLogin }));
      },
      updateTip(tip: string) {
        set((state) => ({ tip }));
      },
    }),
    {
      name: USER_KEY,
      version: 1,
    }
  )
);
