/*
 * @Description: 
 * @Author: Devin
 * @Date: 2023-03-28 11:37:22
 */
import { createApi } from '@reduxjs/toolkit/query/react';
import { LoginInput } from '../../pages/login.page';
import { RegisterInput } from '../../pages/register.page';
import customFetchBase from './customFetchBase';
import { GenericResponse, IResetPasswordRequest } from './types';
import { getCookie,setCookie, deleteCookie } from '../../utils/cookie';
import { toast } from 'react-toastify';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    registerUser: builder.mutation<GenericResponse, RegisterInput>({
      query(data) {
        return {
          url: 'auth/register',
          method: 'POST',
          body: data,
        };
      },
    }),
    loginUser: builder.mutation<
      { access_token: string; status: string },
      LoginInput
    >({
      query(data) {
        return {
          url: 'auth/login',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     console.log('123')
      //     // await queryFulfilled;
      //     // await dispatch(userApi.endpoints.getMe.initiate(null));
      //   } catch (error) {}
      // },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
        };
      },
    }),
    verifyEmail: builder.mutation<GenericResponse, string>({
      query(verificationCode) {
        return {
          url: `auth/verifyemail/${verificationCode}`,
          credentials: 'include',
        };
      },
    }),
    forgotPassword: builder.mutation<GenericResponse, { email: string }>({
      query(body) {
        return {
          url: `auth/forgotpassword`,
          method: 'POST',
          credentials: 'include',
          body,
        };
      },
    }),
    resetPassword: builder.mutation<GenericResponse, IResetPasswordRequest>({
      query({ resetToken, password, passwordConfirm }) {
        return {
          url: `auth/resetpassword/${resetToken}`,
          method: 'PATCH',
          body: { password, passwordConfirm },
          credentials: 'include',
        };
      },
    }),
  }),
});

let tokenTimeoutId:any;

export function startTokenTimer() {
  // 设置定时器，每 30 分钟调用一次 refreshAuth() 函数
  tokenTimeoutId = setInterval(refreshAuth, 30 * 60 * 1000);
}

export  async function refreshAuth() {
  try {
    // 调用 auth/refresh 接口并获取新的 token
    const response = await fetch('/api/auth/refresh', {
      method: 'GET',
      credentials: 'include'
    });
    
    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    // const newToken = data.token;
     setCookie("mojolicious", data?.access_token, 30000);

  } catch (error) {
    // 刷新 token 失败，清除本地存储中的 token 并停止计时器
    // localStorage.removeItem('token');
      toast.error('Login has expired. Please log in again', {
        position: 'top-right',
      });
      deleteCookie('mojolicious')
      setTimeout(() => {
          window.location.reload();
      },1500)
      // clearInterval(tokenTimeoutId);
  }
}



export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
