import type { LocaleType } from "./index";

const en: LocaleType = {
  WIP: "WIP...",
  LayoutInfo: {
    Welcome: "Welcome to Pointer.ai!",
    loginDescription: "Let's start the AI journey!",
    email: "email",
    password: "password",
    username: "username",
    forgetPassword: "forget password?",
    forgetPassword2: "forget password",
    confirmPassword: "confirm password",
    login: "LOGIN",
    needCount: "Need an account number?",
    clickToRegister: "Click here to sign up",
    registerDescription: "Sign up for your AI tour!",
    haveAccount: "Have an account already?",
    register: "register",
    sendMailInfo:
      "We have sent a verification email to your mailbox, please check!",
    backLogin: "Back Login",
    forgetPasswordDescription:
      "Enter your email address and we will send you a verification email",
    getPasswordResetLink: "Get the password reset link",
    VerifyEmail: "Verification Email",
    VerificationCode: "Verification Code",
    resetPassword: "Reset Password",
    enterNewPassword: "Please enter a new password",
  },
  Error: {
    Unauthorized:
      "Unauthorized access, please enter access code in settings page.",
  },
  ChatItem: {
    ChatItemCount: (count: number) => `${count} messages`,
  },
  Chat: {
    SubTitle: (count: number) => `${count} messages with ChatGPT`,
    Actions: {
      ChatList: "Go To Chat List",
      CompressedHistory: "Compressed History Memory Prompt",
      more: "More",
      Export: "Export All Messages as Markdown",
      Copy: "Copy",
      Stop: "Stop",
      Retry: "Retry",
    },
    Typing: "Typing…",
    Requesting: "Requesting",
    Input: (submitKey: string) =>
      `Type something and press ${submitKey} to send`,
    Send: "Send",
  },
  Export: {
    Title: "All Messages",
    Copy: "Copy All",
    Download: "Download",
    Insufficient: "Insufficient Balance",
    InsufficientContent: "Insufficient Balance, Please Contact Administrator",
    BillingRulesFollow: "The Billing Rules Are As Follows",
    Contact: "Contact the wechat below to recharge",
    Scan: "Scan QR code to contact:",
    ScanPay: "Scan QR code to pay:",
    ScanPayTip:
      "（Please note your rechargeable account, and add wechat if you do not recharge successfully within 2 hours.）",
  },

  Memory: {
    Title: "Memory Prompt",
    EmptyContent: "Nothing yet.",
    Copy: "Copy All",
  },
  Home: {
    NewChat: "New Chat",
    PromptsStore: "Prompts Store",
    DeleteChat: "Confirm to delete the selected conversation?",
  },
  Settings: {
    Title: "Settings",
    Balance: "Balance(Unit: yuan)",
    SubTitle: "All Settings",
    Actions: {
      ClearAll: "Clear All Data",
      ResetAll: "Reset All Settings",
      Close: "Close",
    },
    Lang: {
      Name: "语言",
      Options: {
        cn: "中文",
        en: "English",
      },
    },
    Avatar: "Avatar",
    Update: {
      Version: (x: string) => `Version: ${x}`,
      IsLatest: "Latest version",
      CheckUpdate: "Check Update",
      IsChecking: "Checking update...",
      FoundUpdate: (x: string) => `Found new version: ${x}`,
      GoToUpdate: "Update",
    },
    SendKey: "Send Key",
    Theme: "Theme",
    TightBorder: "Tight Border",
    Streaming: "Streaming",
    HistoryCount: {
      Title: "Attached Messages Count",
      SubTitle: "Number of sent messages attached per request",
    },
    CompressThreshold: {
      Title: "History Compression Threshold",
      SubTitle:
        "Will compress if uncompressed messages length exceeds the value",
    },
    Token: {
      Title: "API Key",
      SubTitle: "Use your key to ignore access code limit",
      Placeholder: "OpenAI API Key",
    },
    AccessCode: {
      Title: "Access Code",
      SubTitle: "Access control enabled",
      Placeholder: "Need Access Code",
    },
    Model: "Model",
    Logout: "Logout",
    rechargingCard: "Recharging Card",
    rechargingCardAdmin: "Admin Recharging Card",
    Billing: "Accounting Rule",
    Temperature: {
      Title: "Temperature",
      SubTitle: "A larger value makes the more random output",
    },
    MaxTokens: {
      Title: "Max Tokens",
      SubTitle: "Maximum length of input tokens and generated tokens",
    },
    requestTimeout: {
      Title: "Request Timeout（unit:s）",
      SubTitle: "Timeout of a single network request",
    },
    PresencePenlty: {
      Title: "Presence Penalty",
      SubTitle:
        "A larger value increases the likelihood to talk about new topics",
    },
  },
  Store: {
    DefaultTopic: "New Conversation",
    BotHello: "Hello! How can I assist you today?",
    Error: "Something went wrong, please try again later.",
    Prompt: {
      History: (content: string) =>
        "This is a summary of the chat history between the AI and the user as a recap: " +
        content,
      Topic:
        "Provide a brief topic of the sentence without explanation. If there is no topic, return 'Chitchat'.",
      Summarize:
        "Summarize our discussion briefly in 50 characters or less to use as a prompt for future context.",
    },
    ConfirmClearAll: "Confirm to clear all chat and setting data?",
  },
  Copy: {
    Success: "Copied to clipboard",
    Failed: "Copy failed, please grant permission to access clipboard",
  },
  Measure: {
    tip: "Save money by opening new conversations if you don't need context",
  },
  gpt3: {
    tip: "If you're not satisfied with the answer you can try the stronger gpt4, switch to Settings",
  },
  News: {
    title: "News Events",
  },
  conversationsHistory: {
    maximum: "You have reached the maximum number of conversations",
  },
};

export default en;
