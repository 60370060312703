/*
 * @Description:
 * @Author: Devin
 * @Date: 2023-03-28 11:37:22
 */
import { Outlet } from "react-router-dom";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../styles/globals.scss";
import "../styles/markdown.scss";
import "../styles/prism.scss";
import Footer from "./Footer";
import Header from "./Header";
import {
  Message,
  SubmitKey,
  getChatStore,
  useUserStore,
  useAccessStore,
  ChatSession,
} from "../store";

function useSwitchTheme() {
  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");
  const config = ChatStore((state: any) => state.config);

  useEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");

    if (config.theme === "dark") {
      document.body.classList.add("dark");
    } else if (config.theme === "light") {
      document.body.classList.add("light");
    }

    const themeColor = getComputedStyle(document.body)
      .getPropertyValue("--theme-color")
      .trim();
    const metaDescription = document.querySelector('meta[name="theme-color"]');
    metaDescription?.setAttribute("content", themeColor);
  }, [config.theme]);
}

const Layout = () => {
  useSwitchTheme();
  return (
    <>
      {/* <Header /> */}
      <Outlet />
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
