import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../components/FormInput';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useResetPasswordMutation } from '../redux/api/authApi';
import { Link } from 'react-router-dom';
import * as layoutStyles from './styles'
import Locale from "../locales";
const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: var(--primary);
  font-weight: 500;

  &:hover {
  background-color: var(--primary);
    transform: translateY(-2px);
  }
`;

const resetPasswordSchema = object({
  password: string()
    .nonempty('需要填入密码')
    .min(8, 'Password must be more than 8 characters'),
  passwordConfirm: string().nonempty('Please confirm your password'),
}).refine((data) => data.password === data.passwordConfirm, {
  message: '密码不匹配',
  path: ['passwordConfirm'],
});

export type ResetPasswordInput = TypeOf<typeof resetPasswordSchema>;

const ResetPasswordPage = () => {
  const { resetToken } = useParams<{ resetToken: string }>();

  const methods = useForm<ResetPasswordInput>({
    resolver: zodResolver(resetPasswordSchema),
  });

  // 👇 API Login Mutation
  const [resetPassword, { isLoading, isError, error, isSuccess }] =
    useResetPasswordMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
      toast.success('密码更新成功，请登录', {
        position: 'top-right',
      });
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ResetPasswordInput> = (values) => {
    resetPassword({ ...values, resetToken: resetToken! });
  };

  return (
    <Container
      maxWidth={false}
      sx={layoutStyles.container}
    >
      <Box
        sx={layoutStyles.box}
      >
        <Typography
          textAlign='center'
          component='h2'
          sx={layoutStyles.TypographySm}
        >
          { Locale.LayoutInfo.resetPassword }
        </Typography>

        <Typography
          sx={{
            fontSize: 15,
            width: '100%',
            textAlign: 'center',
            mb: '1rem',
            color: 'white',
          }}
        >
          { Locale.LayoutInfo.enterNewPassword }
        </Typography>
        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            maxWidth='27rem'
            width='100%'
            sx={layoutStyles.formBox}
          >
            <FormInput name='password' label={ Locale.LayoutInfo.password } type='password' />
            <FormInput
              name='passwordConfirm'
              label={ Locale.LayoutInfo.confirmPassword }
              type='password'
            />

            <LoadingButton
              variant='contained'
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type='submit'
              loading={isLoading}
            >
              { Locale.LayoutInfo.resetPassword }
            </LoadingButton>
             <Typography
              sx={{ fontSize: '0.9rem', mt: '1rem', textAlign: 'center' }}
            >
              <Link to='/login' style={{ color: '#333' }}>
                { Locale.LayoutInfo.backLogin }
              </Link>
            </Typography>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
