const cn = {
  WIP: "该功能仍在开发中……",
  LayoutInfo: {
    Welcome: "欢迎来到Pointer.ai！",
    loginDescription: "快来开启AI之旅！",
    email: "邮箱",
    password: "密码",
    forgetPassword: "忘记密码?",
    forgetPassword2: "忘记密码",
    confirmPassword: "确认密码",
    username: "姓名",
    login: "登录",
    needCount: "需要个账号?",
    clickToRegister: "点击这里注册",
    registerDescription: "请注册开启你的AI之旅！",
    haveAccount: "已经有账号?",
    register: "注册",
    sendMailInfo: "我们已经向你的邮箱发送验证邮件，请查收！",
    backLogin: "返回登陆",
    forgetPasswordDescription: "输入你的邮箱地址，我们将会发送一封验证邮件给你",
    getPasswordResetLink: "获取密码重置链接",
    VerifyEmail: "验证邮箱",
    VerificationCode: "验证码",
    resetPassword: "重置密码",
    enterNewPassword: "请输入一个新的密码",
  },
  Error: {
    Unauthorized: "现在是未授权状态，请在设置页填写授权码。",
  },
  ChatItem: {
    ChatItemCount: (count: number) => `${count} 条对话`,
  },
  Chat: {
    SubTitle: (count: number) => `与 ChatGPT 的 ${count} 条对话`,
    Actions: {
      ChatList: "查看消息列表",
      CompressedHistory: "查看压缩后的历史 Prompt",
      more: "更多",
      Export: "导出聊天记录",
      Copy: "复制",
      Stop: "停止",
      Retry: "重试",
    },
    Typing: "正在输入…",
    Requesting: "正在请求中...",
    Input: (submitKey: string) => `输入消息，${submitKey} 发送`,
    Send: "发送",
  },
  Export: {
    Title: "导出聊天记录为 Markdown",
    Copy: "全部复制",
    Download: "下载文件",
    Insufficient: "余额不足",
    InsufficientContent:
      "你的余额不足，请联系管理员充值，或直接参考购买问答环节进行充值",
    BillingRulesFollow: "购买问答环节",
    Contact: "联系方式：13067996560（微信）",
    Scan: "扫码联系：",
    ScanPay: "扫码充值：",
    ScanPayTip: "(请备注好您的充值账号，如果2小时内没有充值成功请添加微信。)",
  },
  Memory: {
    Title: "上下文记忆 Prompt",
    EmptyContent: "尚未记忆",
    Copy: "全部复制",
  },
  Home: {
    NewChat: "新的聊天",
    PromptsStore: "AI智能箱",
    DeleteChat: "确认删除选中的对话？",
  },
  Settings: {
    Title: "设置",
    Balance: "余额(单位：元)",
    SubTitle: "设置选项",

    Actions: {
      ClearAll: "清除所有数据",
      ResetAll: "重置所有选项",
      Close: "关闭",
    },
    Lang: {
      Name: "Language",
      Options: {
        cn: "中文",
        en: "English",
      },
    },
    Avatar: "头像",
    Update: {
      Version: (x: string) => `当前版本：${x}`,
      IsLatest: "已是最新版本",
      CheckUpdate: "检查更新",
      IsChecking: "正在检查更新...",
      FoundUpdate: (x: string) => `发现新版本：${x}`,
      GoToUpdate: "前往更新",
    },
    SendKey: "发送键",
    Theme: "主题",
    TightBorder: "紧凑边框",
    Streaming: "Streaming流",
    HistoryCount: {
      Title: "附带历史消息数",
      SubTitle: "每次请求携带的历史消息数",
    },
    CompressThreshold: {
      Title: "历史消息长度压缩阈值",
      SubTitle: "当未压缩的历史消息超过该值时，将进行压缩",
    },
    Token: {
      Title: "API Key",
      SubTitle: "使用自己的 Key 可绕过受控访问限制",
      Placeholder: "OpenAI API Key",
    },
    AccessCode: {
      Title: "访问码",
      SubTitle: "现在是受控访问状态",
      Placeholder: "请输入访问码",
    },
    Model: "模型 (model)",
    Logout: "退出登录",
    rechargingCard: "充值卡充值",
    rechargingCardAdmin: "管理员充值卡",
    Billing: "问答环节",
    Temperature: {
      Title: "随机性 (temperature)",
      SubTitle: "值越大，回复越随机",
    },
    MaxTokens: {
      Title: "单次回复限制 (max_tokens)",
      SubTitle: "单次交互所用的最大 Token 数",
    },
    requestTimeout: {
      Title: "超时时间（单位:秒）",
      SubTitle: "单次网络请求超时时间",
    },
    PresencePenlty: {
      Title: "话题新鲜度 (presence_penalty)",
      SubTitle: "值越大，越有可能扩展到新话题",
    },
  },
  Store: {
    DefaultTopic: "新的聊天",
    BotHello: "有什么可以帮你的吗",
    // Error: "",
    Error: "出错了，稍后重试吧",
    Prompt: {
      History: (content: string) =>
        "这是 ai 和用户的历史聊天总结作为前情提要：" + content,
      Topic:
        "直接返回这句话的简要主题，不要解释，如果没有主题，请直接返回“闲聊”",
      Summarize:
        "简要总结一下你和用户的对话，用作后续的上下文提示 prompt，控制在 50 字以内",
    },
    ConfirmClearAll: "确认清除所有聊天、设置数据？",
  },
  Copy: {
    Success: "已写入剪切板",
    Failed: "复制失败，请赋予剪切板权限",
  },
  Measure: {
    tip: "如果不需要上下文请开启新的对话来节省费用",
  },
  gpt3: {
    tip: "如果对答案不满意 可以试试更强的gpt4，请在设置中切换",
  },
  News: {
    title: "新闻事件",
  },
  conversationsHistory: {
    maximum: "您已达到最大会话数，删除会话后才能保存此会话",
  },
};

export type LocaleType = typeof cn;

export default cn;
