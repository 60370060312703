import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IconButton } from "./button";
import styles from "./home.module.scss";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Input as _Input,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArticleIcon from "@mui/icons-material/Article";
// import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
// import { ReactComponent as GithubIcon } from "../icons/github.svg";
import { ReactComponent as ChatGptIcon } from "../icons/chatgpt.svg";
import { ReactComponent as SendWhiteIcon } from "../icons/send-white.svg";
import { ReactComponent as BrainIcon } from "../icons/brain.svg";
import { ReactComponent as ExportIcon } from "../icons/export.svg";
import { ReactComponent as BotIcon } from "../icons/bot.svg";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import { ReactComponent as DeleteIcon } from "../icons/delete.svg";
import { ReactComponent as EditNoteOutlinedIcon } from "../icons/edit.svg";
import { ReactComponent as SaveOutlinedIcon } from "../icons/save.svg";
import { ReactComponent as LoadingIcon } from "../icons/three-dots.svg";
import { ReactComponent as MenuIcon } from "../icons/menu.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as CopyIcon } from "../icons/copy.svg";
import { ReactComponent as DownloadIcon } from "../icons/download.svg";
import { ReactComponent as LogoutIcon } from "../icons/logout.svg";
import { ReactComponent as AdminIcon } from "../icons/admin.svg";
import { ReactComponent as BillingIcon } from "../icons/billing.svg";
import { ReactComponent as TipIcon } from "../icons/tip.svg";
import { ReactComponent as AdminRecordIcon } from "../icons/adminRecord.svg";
import { ReactComponent as UserRecordIcon } from "../icons/userRecord.svg";
import { ReactComponent as PromptStoreIcon } from "../icons/promptStore.svg";
import { Markdown } from "./markdown";
import { Settings } from "./settings";
import { Emoji } from "emoji-picker-react";

import Tooltip from "@mui/material/Tooltip";
import drImg from "../assets/wechat.jpeg";
import taobaoImg from "../assets/taobaoshop.jpeg";
import Measure from "../pages/measure";
import {
  Message,
  SubmitKey,
  getChatStore,
  useUserStore,
  useAccessStore,
  ChatSession,
} from "../store";
import { useLogoutUserMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { showModal } from "./ui-lib";
import { copyToClipboard, downloadAs, isIOS, selectOrCopy } from "../utils";
import Locale from "../locales";
import { REPO_URL } from "../constant";
import { ControllerPool, getLatestPost } from "../requests";
import { deleteCookie } from "../utils/cookie";
import news from "./news";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import { styled } from "@mui/material/styles";
import RechargingForm from "../pages/rechargingform.page";

export function Loading(props: { noLogo?: boolean }) {
  return (
    <div className={styles["loading-content"]}>
      {!props.noLogo && <BotIcon />}
      <LoadingIcon />
    </div>
  );
}

const Input = styled(_Input)`
  max-width: 87%;
  height: 1.5rem;
  border-radius: 0.1rem;
  font-size: 14px;
`;

export function Avatar(props: { role: Message["role"] }) {
  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");

  const config = ChatStore((state: any) => state.config);

  if (props.role === "assistant") {
    return <BotIcon />; //style={styleIcon}
  }

  return (
    <div className={styles["user-avtar"]}>
      <Emoji unified={config.avatar} size={18} />
    </div>
  );
}

// const onGetConversationHistory = async () => {
//   try {
//     const data = await getConversationHistory();
//     console.log("data: ", data);
//   } catch (err) {
//     toast.error("云端数据获取错误", {
//       position: "top-right",
//     });
//   }
// };

// const onAddConversationHistory = async (conversationHistory: {
//   title: string;
//   content: string;
// }) => {
//   try {
//     const data = await addConversationHistory(conversationHistory);
//     console.log("data: ", data);
//   } catch (err) {
//     toast.error("云端数据保存错误", {
//       position: "top-right",
//     });
//   }
// };

export function ChatItem(props: {
  onClick?: () => void;
  onDelete?: () => void;
  onEdit?: (isEdit: boolean) => void;
  onChangeTitle?: (e: any) => void;
  setTmpTitle?: (v: any) => void;
  onUpdateSessionTitle?: (title: string) => void;
  isEdit: boolean;
  title: string;
  count: number;
  time: string;
  selected: boolean;
  index: number;
  tmpTitle: any;
}) {
  return (
    <div
      className={`${styles["chat-item"]} ${
        props.selected && styles["chat-item-selected"]
      }`}
      onClick={props.onClick}
    >
      <div className={styles["chat-item-title"]}>
        {props.isEdit ? (
          <Input
            fullWidth
            disableUnderline
            className={styles["input-title"]}
            onChange={props.onChangeTitle}
            value={props.tmpTitle[props.index]}
          />
        ) : (
          props.title
        )}
      </div>
      <div className={styles["chat-item-info"]}>
        <div className={styles["chat-item-count"]}>
          {Locale.ChatItem.ChatItemCount(props.count)}
        </div>
        <div className={styles["chat-item-date"]}>{props.time}</div>
      </div>
      <div className={styles["chat-item-delete"]}>
        {props.isEdit ? (
          <SaveOutlinedIcon
            onClick={() => {
              (props as any).onEdit(false);
              (props as any).onUpdateSessionTitle(props.tmpTitle[props.index]);
            }}
            style={{ marginRight: "5px" }}
          />
        ) : (
          <React.Fragment>
            <EditNoteOutlinedIcon
              onClick={() => {
                (props as any).setTmpTitle({
                  ...props.tmpTitle,
                  [props.index]: props.title,
                });
                (props as any).onEdit(true);
              }}
              style={{ marginRight: "5px" }}
            />
            <DeleteIcon
              onClick={props.onDelete}
              style={{ marginRight: "5px" }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export function ChatList() {
  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");

  const [
    sessions,
    selectedIndex,
    selectSession,
    removeSession,
    onEditSession,
    onUpdateSessionTitle,
    createNewSession,
    getIndexSession,
    onGetSession,
  ] = ChatStore((state: any) => [
    state.sessions,
    state.currentSessionIndex,
    state.selectSession,
    state.removeSession,
    state.onEditSession,
    state.onUpdateSessionTitle,
    state.newSession,
    state.getIndexSession,
    state.onGetSession,
  ]);
  const [isFirstLogin] = useUserStore((state: any) => [state.isFirstLogin]);

  useEffect(() => {
    if (!user.email) return;
    ChatStore = getChatStore(user.email || "");
    if (isFirstLogin) {
      onGetSession();
    }
    setTimeout(() => {
      const currentSessions: any = getIndexSession(0);
      if (currentSessions.messages.length > 1) {
        // createNewSession();
      }
    }, 0);
  }, [user]);

  const [tmpTitle, setTmpTitle] = useState({});

  useEffect(() => {}, [tmpTitle]);

  const onChangeTitle = (e: any, index: number) => {
    setTmpTitle({ ...tmpTitle, [index]: e.target.value });
  };

  return (
    <div className={styles["chat-list"]}>
      {sessions.map((item: any, i: number) => (
        <ChatItem
          title={item.topic}
          isEdit={item.isEdit}
          time={item.lastUpdate}
          count={item.messages.length}
          key={i}
          index={i}
          onChangeTitle={(e: any) => onChangeTitle(e, i)}
          tmpTitle={tmpTitle}
          setTmpTitle={setTmpTitle}
          selected={i === selectedIndex}
          onClick={() => selectSession(i)}
          onDelete={() => removeSession(i)}
          onEdit={(isEdit: boolean) => {
            onEditSession(i, isEdit);
          }}
          onUpdateSessionTitle={(title: string) => {
            onUpdateSessionTitle(i, title);
          }}
        />
      ))}
    </div>
  );
}

function useSubmitHandler() {
  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");
  const config = ChatStore((state: any) => state.config);
  const submitKey = config.submitKey;

  const shouldSubmit = (e: KeyboardEvent) => {
    if (e.key !== "Enter") return false;

    return (
      (config.submitKey === SubmitKey.AltEnter && e.altKey) ||
      (config.submitKey === SubmitKey.CtrlEnter && e.ctrlKey) ||
      (config.submitKey === SubmitKey.ShiftEnter && e.shiftKey) ||
      (config.submitKey === SubmitKey.MetaEnter && e.metaKey) ||
      (config.submitKey === SubmitKey.Enter &&
        !e.altKey &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey)
    );
  };

  return {
    submitKey,
    shouldSubmit,
  };
}

export function Chat(props: { showSideBar?: () => void }) {
  type RenderMessage = Message & { preview?: boolean };

  const [logoutUser, { isLoading, isSuccess, error, isError }] =
    useLogoutUserMutation();

  const accessStore = useAccessStore();
  const user: any = useUserStore((state: any) => state.user);
  const [tip] = useUserStore((state: any) => [state.tip]);

  let ChatStore = getChatStore(user.email || "");

  const [
    config,
    session,
    sessionIndex,
    isRequireComplete,
    onIsRequireComplete,
    onUserInput,
    updateMessages,
    updateMessage,
    isCountDown,
  ] = ChatStore((state: any) => [
    state.config,
    state.currentSession(),
    state.currentSessionIndex,
    state.isRequireComplete,
    state.onIsRequireComplete,
    state.onUserInput,
    state.updateMessages,
    state.updateMessage,
    state.isCountDown,
  ]);

  useEffect(() => {
    if (isSuccess) {
      // 获取所有cookie的名称
      const cookies = document.cookie.split(";");
      console.log("cookies: ", cookies);

      // 循环遍历并删除每个cookie
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
      }
      window.location.href = "/login";
      accessStore.updateUser({});
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        //
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        if ((error as any).status === 401) {
          toast.error("登陆失效，请重新登陆", {
            position: "top-right",
          });
          deleteCookie("mojolicious");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          return;
        }
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onLogoutHandler = async () => {
    logoutUser();
  };

  const accountingRuleModal = async () => {
    const itemList = [
      {
        key: "1",
        content:
          "gpt3.5：按照token收费，一般费用较低，经过测试，即便是同为3.5模型，收费API的回复能力远超过免费用户，能胜任大部分工作。",
      },
      {
        key: "2",
        content:
          "gpt4.0：目前最领先的GPT模型，在多个领域（包括司法、语言、金融）超过大部分人，平均token价格是gpt3.5的20倍-30倍，适用于对回答质量要求极高的用户",
      },
      {
        key: "3",
        content:
          "token的计算主要取决于你对上下文的要求，可以在设置里设置您想每次携带的上下文信息数量，如果最大的数量依然无法满足您，请联系我们的工作人员。上下文推断能力是ChatGPT很重要的能力，请善于利用。" +
          "举个例子，第一句话你说自己是小王，如果上下文长度是1的时候，第二句你问我是谁的时候，GPT可以回答出你是谁。如果再问一句，因为长度" +
          "只有1，所以就丢失了第一句，GPT就不知道你是谁了。上下文很适合需要追问的场景。",
      },
      {
        key: "4",
        content:
          "为了保证稳定性，我们所有的服务都来自付费API，如遇到问题或者想加入讨论群组，请联系下图微信",
      },
      {
        key: "5",
        content:
          "如果您的账户余额不足，我们会在您的账户余额不足时，自动停止服务，充值方式请扫描下方微信二维码联系我们。我们承诺，" +
          "不通过任何方式滥用OpenAI API，遵守技术规范，如若因不可抗力服务被迫停止，我们会对所有会员承担退款的全部义务。",
      },
    ];
    showModal({
      title: Locale.Settings.Billing,
      children: (
        <div className="markdown-body">
          <Typography variant="h4" gutterBottom>
            {Locale.Export.BillingRulesFollow}
          </Typography>
          <List component="ol" style={{ padding: "0" }}>
            {itemList.map((item) => {
              return (
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginBottom: "5px",
                  }}
                  key={item.key}
                >
                  <span>{`${item.key}、`}</span>
                  <span>{`${item.content}`}</span>
                </div>
              );
            })}

            <li>
              <div style={{ textAlign: "center" }}>
                {/* <img src={taobaoImg} style={{ width: "50%" }} /> */}
                <img src={drImg} style={{ width: "50%" }} />
              </div>
            </li>
          </List>
        </div>
      ),
      style: {
        maxHeight: "60vh",
      },
    });
  };

  useEffect(() => {
    onIsRequireComplete(false);
  }, []);

  const [userInput, setUserInput] = useState("");
  const [isLoadings, setIsLoadings] = useState(false);
  const { submitKey, shouldSubmit } = useSubmitHandler();

  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    let interval: any = null;
    setSeconds(10);
    interval = setInterval(() => {
      setSeconds((v: any) => {
        if (v > 0 && isCountDown) {
          return v - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
  }, [isCountDown]);

  // submit user input
  const onUserSubmit = () => {
    if (userInput.length <= 0) return;
    setIsLoadings(true);
    onUserInput(userInput).then(() => {
      setIsLoadings(false);
      setTimeout(() => {
        setUserInput("");
      }, 0);
    });

    inputRef.current?.focus();
  };

  // stop response
  const onUserStop = (messageIndex: number) => {
    ControllerPool.stop(sessionIndex, messageIndex);
    setTimeout(() => {
      updateMessage(sessionIndex, messageIndex, (message: any) => {
        message.streaming = false;
      });
    }, 500);
  };

  // check if should send message
  const onInputKeyDown = (e: KeyboardEvent) => {
    if (shouldSubmit(e)) {
      onUserSubmit();
      e.preventDefault();
    }
  };
  const onRightClick = (e: any, message: Message) => {
    // auto fill user input
    if (message.role === "user") {
      setUserInput(message.content);
    }

    // copy to clipboard
    if (selectOrCopy(e.currentTarget, message.content)) {
      e.preventDefault();
    }
  };

  const onResend = (botIndex: number) => {
    // find last user input message and resend
    for (let i = botIndex; i >= 0; i -= 1) {
      if (messages[i].role === "user") {
        const content: string = messages[i].content;
        setIsLoadings(true);
        const data = JSON.parse(JSON.stringify(messages));
        data.splice(i, 2);
        updateMessages(data);
        onUserInput(content).then(() => setIsLoadings(false));
        return;
      }
    }
  };

  // for auto-scroll
  const latestMessageRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // wont scroll while hovering messages
  const [autoScroll, setAutoScroll] = useState(false);

  // preview messages
  const messages = (session.messages as RenderMessage[])
    .concat(
      isLoadings
        ? [
            {
              role: "assistant",
              content: "……",
              date: new Date().toLocaleString(),
              preview: true,
            },
          ]
        : []
    )
    .concat(
      userInput.length > 0
        ? [
            {
              role: "user",
              content: userInput,
              date: new Date().toLocaleString(),
              preview: true,
            },
          ]
        : []
    );

  // auto scroll
  useLayoutEffect(() => {
    setTimeout(() => {
      const dom = latestMessageRef.current;
      if (dom && !isIOS() && autoScroll) {
        dom.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 500);
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    card_id: "",
    password: "",
  });

  const handleClickOpen = () => {
    setForm({ card_id: "", password: "" });
    setOpen(true);
  };

  return (
    <div className={styles.chat} key={session.id}>
      <RechargingForm
        open={open}
        setOpen={setOpen}
        form={form}
        setForm={setForm}
      />
      <div className={styles["window-header"]}>
        <div
          className={styles["window-header-title"]}
          onClick={props?.showSideBar}
        >
          <div className={styles["window-header-main-title"]}>
            {session.topic}
          </div>
          <div className={styles["window-header-sub-title"]}>
            {Locale.Chat.SubTitle(session.messages.length)}
          </div>
        </div>
        <div className={styles["window-actions"]}>
          {(accessStore.user as any)?.role == "admin" && (
            <div className={styles["window-action-button"]}>
              <IconButton
                icon={<AdminRecordIcon />}
                bordered
                title={Locale.Settings.rechargingCardAdmin}
                onClick={() => {
                  window.location.href = "/recharging-card";
                }}
              />
            </div>
          )}
          <div className={styles["window-action-button"]}>
            <IconButton
              icon={<UserRecordIcon />}
              bordered
              title={Locale.Settings.rechargingCard}
              onClick={() => {
                handleClickOpen();
              }}
            />
          </div>
          <div className={styles["window-action-button"] + " " + styles.mobile}>
            <IconButton
              icon={<MenuIcon />}
              bordered
              title={Locale.Chat.Actions.ChatList}
              onClick={props?.showSideBar}
            />
          </div>
          {/* <div className={styles["window-action-button"] + " " + styles.pc}>
            <IconButton
              icon={<BrainIcon />}
              bordered
              title={Locale.Chat.Actions.CompressedHistory}
              onClick={() => {
                showMemoryPrompt(session);
              }}
            />
          </div> */}
          <div className={styles["window-action-button"] + " " + styles.pc}>
            <IconButton
              icon={<ExportIcon />}
              bordered
              title={Locale.Chat.Actions.Export}
              onClick={() => {
                exportMessages(session.messages, session.topic);
              }}
            />
          </div>
          <div className={styles["window-action-button"] + " " + styles.pc}>
            <IconButton
              icon={<BillingIcon />}
              bordered
              title={Locale.Settings.Billing}
              onClick={() => {
                accountingRuleModal();
              }}
            />
          </div>
          <div className={styles["window-action-button"] + " " + styles.pc}>
            <IconButton
              icon={<LogoutIcon />}
              bordered
              title={Locale.Settings.Logout}
              onClick={() => {
                onLogoutHandler();
              }}
            />
          </div>

          <div className={styles.mobile}>
            <div className={styles["window-action-button"]}>
              <IconButton
                icon={<MoreVertSharpIcon />}
                bordered
                title={Locale.Chat.Actions.more}
                onClick={(event) => handleClick(event)}
              />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ top: "3px" }}
            >
              <MenuItem style={{ padding: "3px 3px" }}>
                <IconButton
                  icon={<BillingIcon />}
                  bordered
                  title={Locale.Settings.Billing}
                  onClick={() => {
                    accountingRuleModal();
                  }}
                />
              </MenuItem>
              <MenuItem style={{ padding: "3px 3px" }}>
                {/* <IconButton
                  icon={<BrainIcon />}
                  bordered
                  title={Locale.Chat.Actions.CompressedHistory}
                  onClick={() => {
                    showMemoryPrompt(session);
                  }}
                /> */}

                <IconButton
                  icon={<ExportIcon />}
                  bordered
                  title={Locale.Chat.Actions.Export}
                  onClick={() => {
                    exportMessages(session.messages, session.topic);
                  }}
                />
              </MenuItem>
              <MenuItem style={{ padding: "3px 3px" }}>
                <IconButton
                  icon={<LogoutIcon />}
                  bordered
                  title={Locale.Settings.Logout}
                  onClick={() => {
                    onLogoutHandler();
                  }}
                />
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div className={styles["chat-body"]}>
        {messages.map((message, i) => {
          const isUser = message.role === "user";
          return (
            <div
              key={i}
              className={
                isUser ? styles["chat-message-user"] : styles["chat-message"]
              }
            >
              <div className={styles["chat-message-container"]}>
                <div className={styles["chat-message-avatar"]}>
                  <Avatar role={message.role} />
                </div>
                {message.preview && (
                  <div className={styles["chat-message-status"]}>
                    {Locale.Chat.Typing}
                  </div>
                )}
                {message.streaming && isCountDown && (
                  <div className={styles["chat-message-status"]}>
                    {Locale.Chat.Requesting +
                      `${seconds > 0 ? seconds + "s" : ""}`}
                  </div>
                )}
                <div className={styles["chat-message-item"]}>
                  {!isUser && (
                    <div className={styles["chat-message-top-actions"]}>
                      {message.streaming ? (
                        <div
                          className={styles["chat-message-top-action"]}
                          onClick={() => onUserStop(i)}
                        >
                          {Locale.Chat.Actions.Stop}
                        </div>
                      ) : (
                        <div
                          className={styles["chat-message-top-action"]}
                          onClick={() => onResend(i)}
                        >
                          {Locale.Chat.Actions.Retry}
                        </div>
                      )}

                      <div
                        className={styles["chat-message-top-action"]}
                        onClick={() => copyToClipboard(message.content)}
                      >
                        {Locale.Chat.Actions.Copy}
                      </div>
                    </div>
                  )}
                  {(message.preview || message.content.length === 0) &&
                  !isUser ? (
                    message.streaming || !tip ? (
                      <LoadingIcon />
                    ) : (
                      <div>{tip}</div>
                    )
                  ) : (
                    <React.Fragment>
                      <div
                        className="markdown-body"
                        onContextMenu={(e) => onRightClick(e, message)}
                      >
                        <Markdown content={message.content} />
                      </div>
                    </React.Fragment>
                  )}
                </div>

                {!isUser && !message.preview && (
                  <div className={styles["chat-message-actions"]}>
                    {/* && config.modelConfig.model =="gpt-3.5-turbo-0301" */}
                    {i != 0 &&
                    messages.length - 1 == i &&
                    config.modelConfig.model == "gpt-3.5-turbo-0301" &&
                    session.lastModel == "gpt-3.5-turbo-0301" ? (
                      <Tooltip title={Locale.gpt3.tip} placement="bottom">
                        <TipIcon
                          style={{
                            width: "16px",
                            height: "16px",
                            margin: "0 9px",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <span></span>
                    )}
                    <div className={styles["chat-message-action-date"]}>
                      {message.date.toLocaleString()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div ref={latestMessageRef} style={{ opacity: 0, height: "2em" }}>
          -
        </div>
      </div>
      <div className={styles["chat-input-panel"]}>
        <div className={styles["line-measure"]}>
          <Measure value={session.lastBalance}></Measure>
        </div>
        <div className={styles["chat-input-panel-inner"]}>
          <textarea
            ref={inputRef}
            className={styles["chat-input"]}
            placeholder={Locale.Chat.Input(submitKey)}
            rows={3}
            onInput={(e) => setUserInput(e.currentTarget.value)}
            value={userInput}
            onKeyDown={(e) => onInputKeyDown(e as any)}
            onFocus={() => setAutoScroll(true)}
            onBlur={() => setAutoScroll(false)}
            autoFocus
          />
          <IconButton
            icon={<SendWhiteIcon />}
            text={Locale.Chat.Send}
            className={styles["chat-input-send"] + " no-dark"}
            onClick={onUserSubmit}
            disabled={isRequireComplete}
          />
        </div>
      </div>
    </div>
  );
}

function useSwitchTheme() {
  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");
  const config = ChatStore((state: any) => state.config);

  useEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");

    if (config.theme === "dark") {
      document.body.classList.add("dark");
    } else if (config.theme === "light") {
      document.body.classList.add("light");
    }

    const themeColor = getComputedStyle(document.body)
      .getPropertyValue("--theme-color")
      .trim();
    const metaDescription = document.querySelector('meta[name="theme-color"]');
    metaDescription?.setAttribute("content", themeColor);
  }, [config.theme]);
}

function exportMessages(messages: Message[], topic: string) {
  const mdText =
    `# ${topic}\n\n` +
    messages
      .map((m) => {
        return m.role === "user" ? `## ${m.content}` : m.content.trim();
      })
      .join("\n\n");
  const filename = `${topic}.md`;

  showModal({
    title: Locale.Export.Title,
    children: (
      <div className="markdown-body">
        <pre className={styles["export-content"]}>{mdText}</pre>
      </div>
    ),
    actions: [
      <IconButton
        key="copy"
        icon={<CopyIcon />}
        bordered
        text={Locale.Export.Copy}
        onClick={() => copyToClipboard(mdText)}
      />,
      <IconButton
        key="download"
        icon={<DownloadIcon />}
        bordered
        text={Locale.Export.Download}
        onClick={() => downloadAs(mdText, filename)}
      />,
    ],
  });
}

function showMemoryPrompt(session: ChatSession) {
  showModal({
    title: `${Locale.Memory.Title} (${session.lastSummarizeIndex} of ${session.messages.length})`,
    children: (
      <div className="markdown-body">
        <pre className={styles["export-content"]}>
          {session.memoryPrompt || Locale.Memory.EmptyContent}
        </pre>
      </div>
    ),
    actions: [
      <IconButton
        key="copy"
        icon={<CopyIcon />}
        bordered
        text={Locale.Memory.Copy}
        onClick={() => copyToClipboard(session.memoryPrompt)}
      />,
    ],
  });
}

const useHasHydrated = () => {
  const [hasHydrated, setHasHydrated] = useState<boolean>(false);

  useEffect(() => {
    setHasHydrated(true);
  }, []);

  return hasHydrated;
};

export function Home() {
  const [isFirstLogin, setIsFirstLogin] = useUserStore((state: any) => [
    state.isFirstLogin,
    state.setIsFirstLogin,
  ]);
  const loading = !useHasHydrated();
  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");

  const [showSideBar, setShowSideBar] = useState(true);
  const [
    createNewSession,
    currentIndex,
    removeSession,
    isShowModal,
    onShowModal,
  ] = ChatStore((state: any) => [
    state.newSession,
    state.currentSessionIndex,
    state.removeSession,
    state.isShowModal,
    state.onShowModal,
  ]);

  const accessStore = useAccessStore();

  useEffect(() => {
    if (!isShowModal) return;
    showModal({
      title: Locale.Export.Insufficient,
      children: (
        <div className="markdown-body">
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              lineHeight: "50px",
            }}
          >
            {Locale.Export.InsufficientContent}
          </div>
          {/* <div style={{ fontSize: "15px", lineHeight: "50px" }}>
            {Locale.Export.Contact}
          </div> */}
          <div
            style={{ fontSize: "15px", lineHeight: "50px" }}
          >{`${Locale.Export.ScanPay}`}</div>
          <div style={{ fontSize: "12px", lineHeight: "30px" }}>
            {Locale.Export.ScanPayTip}
          </div>
          <div style={{ margin: "auto" }}>
            <img src={taobaoImg} style={{ width: "50%" }} />
            <img src={drImg} style={{ width: "50%" }} />
          </div>
        </div>
      ),
      style: {
        maxHeight: "60vh",
      },
    });
    onShowModal(false);
  }, [isShowModal]);

  const getLatestPosts = async () => {
    const data: any = await getLatestPost();
    return data;
  };

  useEffect(() => {
    if (!isFirstLogin) return;
    news();
    setIsFirstLogin(false);
  }, [isFirstLogin]);

  // setting
  const [openSettings, setOpenSettings] = useState(false);
  const config = ChatStore((state: any) => state.config);

  useSwitchTheme();

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={`${
        config.tightBorder ? styles["tight-container"] : styles.container
      }`}
    >
      <div
        className={styles.sidebar + ` ${showSideBar && styles["sidebar-show"]}`}
      >
        <div className={styles["sidebar-header"]}>
          <div className={styles["sidebar-title"]}>ChatGPT商用版</div>
          <div className={styles["sidebar-sub-title"]}>
            用AI改变你的工作方式
          </div>
          <div className={styles["sidebar-logo"]}>
            <ChatGptIcon />
          </div>
        </div>

        <div
          className={styles["sidebar-body"]}
          onClick={() => {
            setOpenSettings(false);
            setShowSideBar(false);
          }}
        >
          <ChatList />
        </div>

        <div className={styles["sidebar-tail"]}>
          <div className={styles["sidebar-actions"]}>
            <div className={styles["sidebar-action"] + " " + styles.mobile}>
              <IconButton
                icon={<CloseIcon />}
                onClick={() => {
                  if (window.confirm(Locale.Home.DeleteChat)) {
                    removeSession(currentIndex);
                  }
                }}
              />
            </div>
            <div className={styles["sidebar-action"]}>
              <IconButton
                icon={<SettingsIcon />}
                onClick={() => {
                  setOpenSettings(true);
                  setShowSideBar(false);
                }}
              />
            </div>
            {(accessStore.user as any)?.role == "admin" && (
              <div className={styles["sidebar-action"]}>
                <IconButton
                  icon={<AdminIcon />}
                  onClick={() => {
                    window.location.href = "/admin";
                  }}
                />
              </div>
            )}
            {(accessStore.user as any)?.role == "admin" && (
              <div className={styles["sidebar-action"]}>
                <IconButton
                  icon={<ArticleIcon />}
                  onClick={() => {
                    window.location.href = "/post";
                  }}
                />
              </div>
            )}
            {/* <div className={styles["sidebar-action"]}>
              <a href={REPO_URL} target="_blank">
                <IconButton icon={<GithubIcon />} />
              </a>
            </div> */}
          </div>
          <div className={styles["sidebar-new-chat"]}>
            <IconButton
              icon={<AddIcon />}
              text={Locale.Home.NewChat}
              onClick={() => {
                createNewSession();
                setShowSideBar(false);
              }}
            />
          </div>
        </div>

        {/* <div className={styles["sidebar-prompt-store"]}>
          <IconButton
            icon={<PromptStoreIcon />}
            text={Locale.Home.PromptsStore}
            onClick={() => {
              setShowSideBar(false);
            }}
          />
        </div> */}
      </div>

      <div className={styles["window-content"]}>
        {openSettings ? (
          <Settings
            closeSettings={() => {
              setOpenSettings(false);
              setShowSideBar(true);
            }}
          />
        ) : (
          <Chat key="chat" showSideBar={() => setShowSideBar(true)} />
        )}
      </div>
    </div>
  );
}
