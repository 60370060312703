/*
 * @Description: 
 * @Author: Devin
 * @Date: 2023-03-28 20:00:02
 */
import * as React from "react";

import styles from "./button.module.scss";

export function IconButton(props: {
  onClick?: (event?:any) => void;
  icon: JSX.Element;
  text?: string;
  bordered?: boolean;
  className?: string;
  title?: string;
  disabled?: boolean;
}) {
  return (
    <div
      className={
        styles["icon-button"] +
        ` ${props.bordered && styles.border} ${props.className ?? ""}` +
        ` ${props.disabled ? styles.disabled : ""}`
      }
      onClick={props.onClick}
      title={props.title}
      role="button"
      tabIndex={props.disabled ? -1 : undefined}
      aria-disabled={props.disabled}
    >
      <div className={styles["icon-button-icon"]}>{props.icon}</div>
      {props.text && (
        <div className={styles["icon-button-text"]}>{props.text}</div>
      )}
    </div>
  );
}
