/*
 * @Description:
 * @Author: Devin
 * @Date: 2023-04-07 20:31:21
 */
import { Alert, AlertColor, AlertProps, AlertTitle } from "@mui/material";
import React from "react";
import { showModal } from "./ui-lib";
import Locale from "../locales";
import styles from "./news.module.scss";
import { ReactComponent as LoadingIcon } from "../icons/three-dots.svg";
import { ControllerPool, getLatestPost } from "../requests";

import { Markdown } from "./markdown";

const news = () => {
  getLatestPost().then((res: any) => {
    showModal({
      title: res.data.title,
      children: (
        <div className="markdown-body">
          <Markdown content={res.data.content} />
        </div>
      ),
      style: {
        maxHeight: "70vh",
        minHeight: "50vh",
      },
    });
    console.log("res: ", res.data);
  });
};

export default news;
