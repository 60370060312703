import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
  ControllerPool,
  requestChatStream,
  requestWithPrompt,
  getConversationHistory,
  addConversationHistory,
  UpdateConversationHistory,
  deleteConversationHistory,
} from "../requests";
import { trimTopic } from "../utils";
import { toast } from "react-toastify";

import Locale from "../locales";

export type Message = any;

export enum SubmitKey {
  Enter = "Enter",
  CtrlEnter = "Ctrl + Enter",
  ShiftEnter = "Shift + Enter",
  AltEnter = "Alt + Enter",
  MetaEnter = "Meta + Enter",
}

export enum Theme {
  Auto = "auto",
  Dark = "dark",
  Light = "light",
}

export interface ChatConfig {
  maxToken?: number;
  historyMessageCount: number; // -1 means all
  compressMessageLengthThreshold: number;
  sendBotMessages: boolean; // send bot's message or not
  submitKey: SubmitKey;
  avatar: string;
  theme: Theme;
  tightBorder: boolean;
  stream: boolean;

  modelConfig: {
    model: string;
    temperature: number;
    max_tokens: number;
    max_timeout: number;
    presence_penalty: number;
  };
}

export type ModelConfig = ChatConfig["modelConfig"];

const ENABLE_GPT4 = true;

export const ALL_MODELS = [
  // {
  //   name: "gpt-4",
  //   available: ENABLE_GPT4,
  // },
  {
    name: "gpt-3.5-turbo",
    available: true,
  },
  {
    name: "gpt-3.5-turbo-16k",
    available: true,
  },
  {
    name: "gpt-4",
    available: ENABLE_GPT4,
  },
  // {
  //   name: "gpt-4-32k",
  //   available: ENABLE_GPT4,
  // },
  // {
  //   name: "gpt-4-32k-0314",
  //   available: ENABLE_GPT4,
  // },
  // {
  //   name: "gpt-3.5-turbo",
  //   available: true,
  // },
];

export function isValidModel(name: string) {
  return ALL_MODELS.some((m) => m.name === name && m.available);
}

export function isValidNumber(x: number, min: number, max: number) {
  return typeof x === "number" && x <= max && x >= min;
}

export function filterConfig(config: ModelConfig): Partial<ModelConfig> {
  const validator: {
    [k in keyof ModelConfig]: (x: ModelConfig[keyof ModelConfig]) => boolean;
  } = {
    model(x) {
      return isValidModel(x as string);
    },
    max_tokens(x) {
      return isValidNumber(x as number, 100, 4000);
    },
    max_timeout(x) {
      return isValidNumber(x as number, 20, 40);
    },
    presence_penalty(x) {
      return isValidNumber(x as number, -2, 2);
    },
    temperature(x) {
      return isValidNumber(x as number, 0, 1);
    },
  };

  Object.keys(validator).forEach((k) => {
    const key = k as keyof ModelConfig;
    if (!validator[key](config[key])) {
      delete config[key];
    }
  });

  return config;
}

const DEFAULT_CONFIG: ChatConfig = {
  historyMessageCount: 20,
  compressMessageLengthThreshold: 1000,
  sendBotMessages: true as boolean,
  submitKey: SubmitKey.CtrlEnter as SubmitKey,
  avatar: "1f603",
  theme: Theme.Auto as Theme,
  tightBorder: false,
  stream: true as boolean,

  modelConfig: {
    model: "gpt-3.5-turbo-0301",
    temperature: 1,
    max_tokens: 2000,
    max_timeout: 20,
    presence_penalty: 0,
  },
};

export interface ChatStat {
  tokenCount: number;
  wordCount: number;
  charCount: number;
}

export interface ChatSession {
  id: number;
  isSaved: boolean;
  topic: string;
  memoryPrompt: string;
  messages: Message[];
  stat: ChatStat;
  lastUpdate: string;
  lastSummarizeIndex: number;
  lastBalance: number;
  isEdit: boolean;
  userEmail: string;
  lastModel: string;
}

const DEFAULT_TOPIC = Locale.Store.DefaultTopic;

function createEmptySession(email?: string): ChatSession {
  const createDate = new Date().toLocaleString();

  return {
    id: Date.now(),
    topic: DEFAULT_TOPIC,
    isSaved: false,
    memoryPrompt: "",
    messages: [
      {
        role: "assistant",
        content: Locale.Store.BotHello,
        date: createDate,
      },
    ],
    stat: {
      tokenCount: 0,
      wordCount: 0,
      charCount: 0,
    },
    lastUpdate: createDate,
    lastSummarizeIndex: 0,
    lastBalance: 0,
    isEdit: false,
    userEmail: email || "",
    lastModel: "",
  };
}

interface ChatStores {
  config: ChatConfig;
  sessions: ChatSession[];
  userSessions: ChatSession[];
  currentSessionIndex: number;
  isShowModal: boolean;
  isRequireComplete: boolean;
  user: any;
  isCountDown: boolean;
  setUserSessions: () => void;
  getUser: () => any;
  setUser: (user: any) => void;
  removeSession: (index: number) => void;
  selectSession: (index: number) => void;
  newSession: () => void;
  getIndexSession: (index: number) => void;
  currentSession: () => ChatSession;
  onNewMessage: (message: Message, balance?: string) => void;
  onUserInput: (content: string) => Promise<void>;
  summarizeSession: () => void;
  updateStat: (message: Message) => void;
  updateCurrentSession: (updater: (session: ChatSession) => void) => void;
  updateMessage: (
    sessionIndex: number,
    messageIndex: number,
    updater: (message?: Message) => void
  ) => void;
  updateMessages: (messages?: any) => void;
  getMessagesWithMemory: () => Message[];
  getMemoryPrompt: () => Message;

  getConfig: () => ChatConfig;
  resetConfig: () => void;
  updateConfig: (updater: (config: ChatConfig) => void) => void;
  clearAllData: () => void;
  onShowModal: (isShow: boolean) => void;
  onFilterMessage: () => void;
  onIsRequireComplete: (isRequireComplete: boolean) => void;
  onSaveOrUpdateSession: () => void;
  onGetSession: () => void;
  onUpdateCountDown: (isCountDown: boolean) => void;
}

export const LOCAL_KEY = "chat-next-web-store";

const defaultStore = (set: any, get: any) => ({
  sessions: [createEmptySession()],
  userSessions: [createEmptySession()],
  currentSessionIndex: 0,
  user: {
    email: "",
  },
  config: {
    ...DEFAULT_CONFIG,
  },
  isShowModal: false as boolean,
  isRequireComplete: false as boolean,
  isCountDown: false as boolean,
  setUser(user: any) {
    set(() => ({ user: user }));
  },
  getUser() {
    return get().user;
  },
  resetConfig() {
    set(() => ({ config: { ...DEFAULT_CONFIG } }));
  },

  getConfig() {
    return get().config;
  },
  setUserSessions() {
    const userList = get().sessions.filter(
      (session: any) => session.userEmail == get().user.email
    );
    set(() => ({ userSessions: { ...userList } }));
  },
  updateConfig(updater: any) {
    const config = get().config;
    updater(config);
    set(() => ({ config }));
  },

  selectSession(index: number) {
    set({
      currentSessionIndex: index,
    });
  },

  removeSession(index: number) {
    set((state: any) => {
      let nextIndex = state.currentSessionIndex;
      const sessions = state.sessions;

      const session = sessions[index];
      console.log("session: ", session);
      if (session?.isSaved) {
        const conversationId: string = session.id;
        deleteConversationHistory(conversationId, {
          onCallBack(data: any) {
            console.log("data: ", data);
          },
        });
      }

      if (sessions.length === 1) {
        return {
          currentSessionIndex: 0,
          sessions: [createEmptySession(get().user.email)],
        };
      }

      sessions.splice(index, 1);

      if (nextIndex === index) {
        nextIndex -= 1;
      }

      return {
        currentSessionIndex: nextIndex,
        sessions,
      };
    });
  },

  newSession() {
    set((state: any) => ({
      currentSessionIndex: 0,
      sessions: [createEmptySession(get().user.email)].concat(state.sessions),
    }));
  },

  currentSession() {
    let index = get().currentSessionIndex;
    const sessions = get().sessions;

    if (index < 0 || index >= sessions.length) {
      index = Math.min(sessions.length - 1, Math.max(0, index));
      set(() => ({ currentSessionIndex: index }));
    }

    const session = sessions[index];

    return session;
  },

  onSaveOrUpdateSession() {
    const session = get().currentSession();
    if (session.isSaved && typeof session.id == "string") {
      UpdateConversationHistory(
        session.id,
        { title: session.topic, content: JSON.stringify(session) },
        {
          onCallBack(data: string) {
            console.log("data: ", data);
          },
        }
      );
    } else {
      addConversationHistory(
        { title: session.topic, content: JSON.stringify(session) },
        {
          onCallBack(id: string) {
            const sessions = get().sessions;
            const session = sessions[get().currentSessionIndex];
            (session as any).id = id;
            (session as any).isSaved = true;
            set(() => ({ sessions }));
          },
          onMessage: (message: string) => {
            toast.warning(Locale.conversationsHistory.maximum || message, {
              position: "top-right",
            });
          },
        }
      );
    }
  },

  onGetSession() {
    getConversationHistory({
      onCallBack(data: any) {
        const sessions = get().sessions;

        let idList: string[] = sessions.map((session: any) => session.id);
        // console.log(sessions, idList);
        data.map((item: any) => {
          const content = JSON.parse(item.content);
          if (!content.messages?.length) return;
          if (idList.includes(item.id)) {
            const session = sessions[sessions.findIndex((i: any) => i.id)];
            session.messages = content?.messages;
            session.isSaved = true;
            return;
          }
          sessions.push({
            ...content,
            id: item.id,
            isSaved: true,
          });
        });

        set(() => ({ sessions }));
      },
    });
  },

  getIndexSession(index: number) {
    const sessions = get().sessions;

    if (index < 0 || index >= sessions.length) {
      index = Math.min(sessions.length - 1, Math.max(0, index));
      set(() => ({ currentSessionIndex: index }));
    }

    const session = sessions[index];

    return session;
  },

  onNewMessage(message: any, balance?: string) {
    get().updateCurrentSession((session: any) => {
      session.lastUpdate = new Date().toLocaleString();
      session.lastBalance = Number(balance);
      session.userEmail = get().user.email;
      session.lastModel = get().config.modelConfig.model;
    });
    get().updateStat(message);
    get().summarizeSession();
  },

  onFilterMessage() {
    const sessions = get().sessions;
    const session = sessions[get().currentSessionIndex];
    const lastMessage = session?.messages[session?.messages.length - 1];
    if (lastMessage.content == "" || !lastMessage.content) {
      session?.messages.splice(session?.messages.length - 2, 2);
    }
    set(() => ({ sessions }));
  },

  async onUserInput(content: any) {
    get().onFilterMessage();
    const userMessage: Message = {
      role: "user",
      content,
      date: new Date().toLocaleString(),
    };

    const botMessage: Message = {
      content: "",
      role: "assistant",
      date: new Date().toLocaleString(),
      streaming: true,
    };

    // get recent messages
    const recentMessages = get().getMessagesWithMemory();
    const sendMessages = recentMessages.concat(userMessage);
    const sessionIndex = get().currentSessionIndex;
    const messageIndex = get().currentSession().messages.length + 1;

    // save user's and bot's message
    get().updateCurrentSession((session: any) => {
      session.messages.push(userMessage);
      session.messages.push(botMessage);
    });

    const session = get().currentSession();
    if (session.topic === DEFAULT_TOPIC && session.messages.length >= 3) {
      get().updateCurrentSession(
        (session: any) => (session.topic = trimTopic(content))
      );
    }
    // make request
    console.log("[User Input] ", sendMessages);
    requestChatStream(sendMessages, {
      onMessage(content: any, done: any, balance?: string) {
        // stream response
        if (done) {
          botMessage.streaming = false;
          botMessage.content = content;
          get().onNewMessage(botMessage, balance);
          get().onSaveOrUpdateSession();
          ControllerPool.remove(sessionIndex, messageIndex);
          get().onUpdateCountDown(false);
        } else {
          botMessage.content = content;
          get().onUpdateCountDown(false);
          set(() => ({}));
        }
      },
      onError(error: any) {
        // botMessage.content += "\n\n"
        botMessage.streaming = false;
        get().onUpdateCountDown(false);
        set(() => ({}));
        ControllerPool.remove(sessionIndex, messageIndex);
      },
      onController(controller: any) {
        // collect controller for stop/retry
        // botMessage.streaming = false;
        set(() => ({}));
        ControllerPool.addController(sessionIndex, messageIndex, controller);
      },
      onIsRequireComplete(isRequireComplete: boolean) {
        get().onIsRequireComplete(isRequireComplete);
      },
      filterBot: !get().config.sendBotMessages,
      modelConfig: get().config.modelConfig,
      stream: get().config.stream,
      setShowModal: (isShow: boolean) => {
        botMessage.streaming = false;
        set(() => ({}));
        get().onShowModal(isShow);
      },
    });
  },

  getMemoryPrompt() {
    const session = get().currentSession();

    return {
      role: "system",
      content: Locale.Store.Prompt.History(session.memoryPrompt),
      date: "",
    } as Message;
  },

  getMessagesWithMemory() {
    const session = get().currentSession();
    const config = get().config;
    const n = session.messages.length;
    const recentMessages = session.messages.slice(
      n - config.historyMessageCount
    );

    const memoryPrompt = get().getMemoryPrompt();

    if (session.memoryPrompt) {
      recentMessages.unshift(memoryPrompt);
    }

    return recentMessages;
  },

  updateMessage(
    sessionIndex: number,
    messageIndex: number,
    updater: (message?: Message) => void
  ) {
    const sessions = get().sessions;
    const session = sessions[sessionIndex];
    const messages = session?.messages;
    updater(messages[messageIndex]);
    set(() => ({ sessions }));
  },

  onEditSession(sessionIndex: number, isEdit: boolean) {
    const sessions = get().sessions;
    const session = sessions[sessionIndex];
    session.isEdit = isEdit;
    set(() => ({ sessions }));
  },

  onUpdateSessionTitle(sessionIndex: number, topic: string) {
    const sessions = get().sessions;
    const session = sessions[sessionIndex];
    session.topic = topic;
    set(() => ({ sessions }));
  },

  updateMessages(messages: any) {
    const sessions = get().sessions;
    const session = sessions[get().currentSessionIndex];
    (session as any).messages = messages;
    set(() => ({ sessions }));
  },

  summarizeSession() {
    const session = get().currentSession();

    // if (session.topic === DEFAULT_TOPIC && session.messages.length >= 3) {
    //   // should summarize topic
    //   requestWithPrompt(session.messages, Locale.Store.Prompt.Topic).then(
    //     (res:any) => {

    //     }
    //   );
    // }

    const config = get().config;
    let toBeSummarizedMsgs = session.messages.slice(session.lastSummarizeIndex);
    const historyMsgLength = toBeSummarizedMsgs.reduce(
      (pre: any, cur: any) => pre + cur.content.length,
      0
    );

    if (historyMsgLength > 4000) {
      toBeSummarizedMsgs = toBeSummarizedMsgs.slice(
        -config.historyMessageCount
      );
    }

    // add memory prompt
    toBeSummarizedMsgs.unshift(get().getMemoryPrompt());

    const lastSummarizeIndex = session.messages.length;

    console.log(
      "[Chat History] ",
      toBeSummarizedMsgs,
      historyMsgLength,
      config.compressMessageLengthThreshold
    );

    if (historyMsgLength > config.compressMessageLengthThreshold) {
      requestChatStream(
        toBeSummarizedMsgs.concat({
          role: "system",
          content: Locale.Store.Prompt.Summarize,
          date: "",
        }),
        {
          filterBot: false,
          stream: get().config.stream,
          onMessage(message: any, done: any) {
            session.memoryPrompt = message;
            if (done) {
              console.log("[Memory] ", session.memoryPrompt);
              session.lastSummarizeIndex = lastSummarizeIndex;
            }
          },
          onError(error: any) {
            console.error("[Summarize] ", error);
          },
          onIsRequireComplete(isRequireComplete: boolean) {
            // console.log('isRequireComplete: ', isRequireComplete);
            get().onIsRequireComplete(isRequireComplete);
          },
        }
      );
    }
  },

  updateStat(message: any) {
    get().updateCurrentSession((session: any) => {
      session.stat.charCount += message.content.length;
      // TODO: should update chat count and word count
    });
  },

  onShowModal(isShow: boolean) {
    set(() => ({ isShowModal: isShow }));
  },

  onIsRequireComplete(isRequireComplete: boolean) {
    set(() => ({ isRequireComplete: isRequireComplete }));
  },

  updateCurrentSession(updater: any) {
    const sessions = get().sessions;
    const index = get().currentSessionIndex;
    updater(sessions[index]);
    set(() => ({ sessions }));
  },

  clearAllData() {
    if (window.confirm(Locale.Store.ConfirmClearAll)) {
      localStorage.clear();
      window.location.reload();
    }
  },
  onUpdateCountDown(isCountDown: boolean) {
    set(() => ({ isCountDown }));
  },
});

// export const ChatStore = create<ChatStores>()(
//   persist(
//     (set, get) => ({
//       ...defaultStore(set, get)
//     }),
//     {
//       name: LOCAL_KEY,
//       version: 1,
//     }
//   )
// );

// 创建一个字典来存储不同用户的 ChatStore 实例
export const allChatStores: any = {};

export const getChatStore = (email: string) => {
  // 如果字典中已经存在该用户的 ChatStore 实例，则直接返回之前保存的实例
  if (allChatStores[email]) {
    return allChatStores[email];
  }

  // 如果不存在该用户的 ChatStore 实例，则根据默认的 ChatStore 实例来创建一个新的实例
  const newChatStore = create(
    persist((set, get) => ({ ...defaultStore(set, get) }), {
      name: `${LOCAL_KEY}${email}`,
      version: 1,
    })
  );

  // 将新的 ChatStore 实例保存到字典中
  allChatStores[email] = newChatStore;

  return newChatStore;
};
