import { Box, Container, Typography, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../components/FormInput';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useLoginUserMutation } from '../redux/api/authApi';
import { setCookie, getCookie } from "../utils/cookie";
import { updateBalance } from '../requests'
import { convertToYuan } from '../utils'
import * as layoutStyles from './styles'
const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: var(--primary);
  font-weight: 500;

  &:hover {
  background-color: var(--primary);
    transform: translateY(-2px);
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
`;

const AdminSchema = object({
  email: string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
     amount: string()
    .min(1, 'Amount is required')
});

export type LoginInput = TypeOf<typeof AdminSchema>;

const AdminPage = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(AdminSchema),
  });


  const navigate = useNavigate();
  const location = useLocation();


  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const submitUpdateBalance = async (form: { email: string, amount: string }) => {
    try {
      const res = await updateBalance(form)
      if (res.status === 'success') {
        const { data } = res
        toast.success(`
        ${data.updated_email}：Recharge successfully,  
        account balance is ￥${convertToYuan(data.new_balance)}`, {
          position: 'top-right',
        });
      } else { 
         throw new Error('Recharge failure');
      }
    } catch (e:any) {   
      toast.error(e.message||'Recharge failure', {
            position: 'top-right',
        });
    } 
  }


  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    submitUpdateBalance(values)
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width:'100vw',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          textAlign='center'
          component='h1'
          sx={{
            color: 'var(--primary)',
            fontWeight: 600,
            fontSize: { xs: '2rem', md: '3rem' },
            mb: 2,
            letterSpacing: 1,
          }}
        >
          Welcome Admin System!
        </Typography>
        <Typography
          variant='body1'
          component='h2'
          sx={{  mb: 2 }}
        >
          Balance recharge!
        </Typography>

        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            maxWidth='27rem'
            width='100%'
            sx={layoutStyles.formBox}
          >
            <FormInput name='email' label='Email' type='email' />
            <FormInput name='amount' label='Amount' type='input' placeholder="单位:元" />


            <LoadingButton
              variant='contained'
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type='submit'
            >
              Submit
            </LoadingButton>

              <Typography
              sx={{ fontSize: '0.9rem', mt: '1rem', textAlign: 'center' }}
            >
              <Link to='/'>
                Back to Index
              </Link>
            </Typography>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default AdminPage;
