import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../components/FormInput';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useForgotPasswordMutation } from '../redux/api/authApi';
import * as layoutStyles from './styles'
import Locale from "../locales";
import './styles'

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: var(--primary);
  font-weight: 500;

  &:hover {
   background-color: var(--primary);
    transform: translateY(-2px);
  }
`;

const forgotPasswordSchema = object({
  email: string()
    .nonempty('需要电子邮箱地址')
    .email('电子邮箱地址非法'),
});

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>;

const ForgotPasswordPage = () => {
  const methods = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  // 👇 API Login Mutation
  const [forgotPassword, { isLoading, isError, error, isSuccess, data }] =
    useForgotPasswordMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
    }
    if (isError) {
      console.log(error);
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ForgotPasswordInput> = ({ email }) => {
    // 👇 Executing the forgotPassword Mutation
    forgotPassword({ email });
  };

  if (isSuccess) {
    return (
      <Container
        maxWidth={false}
        sx={layoutStyles.container}
      >
        <Box
          sx={layoutStyles.box}
        >
          <Box
            maxWidth='27rem'
            width='100%'
            height='13rem'
            sx={layoutStyles.TypographySm}
          >
            <Typography textAlign='center' component='h5'>
              {Locale.LayoutInfo.sendMailInfo}
            </Typography>
          </Box>
           <Typography
              sx={{ fontSize: '0.9rem', mt: '1rem', textAlign: 'center' }}
            >
              <Link to='/login' style={{ color: 'var(--primary)' }}>
                {Locale.LayoutInfo.backLogin}
              </Link>
            </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={layoutStyles.container}
    >
      <Box
        sx={layoutStyles.box}
      >
        <Typography
          textAlign='center'
          component='h1'
          sx={layoutStyles.TypographySm}
        >
          {Locale.LayoutInfo.forgetPassword2}
        </Typography>
        <Typography
          sx={{
            fontSize: 15,
            width: '100%',
            textAlign: 'center',
            mb: '1rem',
            color: 'white',
          }}
        >
          {Locale.LayoutInfo.forgetPasswordDescription}
        </Typography>

        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            maxWidth='27rem'
            width='100%'
            sx={layoutStyles.formBox}
          >
            <FormInput name='email' label={Locale.LayoutInfo.email} type='email' 
            style={{color:"var(--primary)"}} />
            <LoadingButton
              variant='contained'
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type='submit'
              loading={isLoading}
            >
              {Locale.LayoutInfo.getPasswordResetLink}
            </LoadingButton>

            <Typography
              sx={{ fontSize: '0.9rem', mt: '1rem', textAlign: 'center' }}
            >
              <Link to='/login' style={{ color: 'var(--primary)' }}>
                {Locale.LayoutInfo.backLogin}
              </Link>
            </Typography>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
