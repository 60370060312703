/*
 * @Description:
 * @Author: Devin
 * @Date: 2023-03-28 20:43:19
 */
import { showToast } from "./components/ui-lib";
import Locale from "./locales";

export function trimTopic(topic: string) {
  const s = topic.split("");
  let lastChar = s[-1]; // 获取 s 的最后一个字符
  let pattern = /[，。！？、]/; // 定义匹配中文标点符号的正则表达式
  while (lastChar && pattern.test(lastChar!)) {
    s.pop();
    lastChar = s[-1];
  }

  return s.join("");
}

export function copyToClipboard(text: string) {
  var input = document.createElement("input");
  input.type = "text";
  input.value = text;
  input.style.position = "fixed";
  input.style.opacity = "0";
  document.body.appendChild(input);
  input.select();
  if (document.execCommand("copy")) {
    showToast(Locale.Copy.Success);
  } else {
    showToast(Locale.Copy.Failed);
  }
  document.body.removeChild(input);
}

export function downloadAs(text: string, filename: string) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function isIOS() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

export function selectOrCopy(el: HTMLElement, content: string) {
  const currentSelection = window.getSelection();

  if (currentSelection?.type === "Range") {
    return false;
  }

  copyToClipboard(content);

  return true;
}

export function queryMeta(key: string, defaultValue?: string): string {
  let ret: string;
  if (document) {
    const meta = document.head.querySelector(
      `meta[name='${key}']`
    ) as HTMLMetaElement;
    ret = meta?.content ?? "";
  } else {
    ret = defaultValue ?? "";
  }

  return ret;
}

let currentId: string;
export function getCurrentCommitId() {
  if (currentId) {
    return currentId;
  }

  currentId = queryMeta("version");

  return currentId;
}

export function convertToYuan(amountInCents: number) {
  if (!amountInCents) {
    return 0;
  }

  return amountInCents / 100;
}

export function convertToPenny(amountInCents: number) {
  if (!amountInCents) {
    return 0;
  }

  return amountInCents * 100;
}

// 定义比较函数
export function sortByUpdatedAt(a: any, b: any) {
  // 解析日期字符串为 Date 对象
  const dateA = new Date(a.updated_at);
  const dateB = new Date(b.updated_at);

  // 使用 getTime() 方法获取日期的时间戳，并进行比较
  if (dateA.getTime() > dateB.getTime()) {
    return -1; // 返回负数表示 a 在 b 之前
  } else if (dateA.getTime() > dateB.getTime()) {
    return 1; // 返回正数表示 a 在 b 之后
  } else {
    return 0; // 返回 0 表示日期相等
  }
}
