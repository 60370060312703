import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { TableRow as _TableRow, TableCell as _TableCell } from "@mui/material";

import {
  getLatestPost,
  deletePost,
  UpdatePost,
  getPost,
  addPost,
} from "../requests";
import { IconButton, Input as _Input } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import BackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Container, Typography, Link as MuiLink } from "@mui/material";
import * as layoutStyles from "./styles";
import FormInput from "../components/FormInput";
import FormText from "../components/FormText";
import { styled } from "@mui/material/styles";
import { hasIn } from "lodash";
import { copyToClipboard } from "../utils";
import { Markdown } from "../components/markdown";
export type LoginInput = TypeOf<typeof loginSchema>;

const loginSchema = object({});

const TableRow = styled(_TableRow)(({ theme }) => ({
  "&": {
    height: "50px", // 您可以将此值更改为所需的高度
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
  "&": {
    height: "50px", // 您可以将此值更改为所需的高度
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface Column {
  id: "ID" | "title" | "content" | "CreatedAt" | "UpdatedAt" | "Operator";
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: any) => any;
}

interface Data {
  ID: string;
  title: string;
  content: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt?: string;
  Operator?: string;
}

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [type, setType] = React.useState("add");
  const [title, setTitle] = React.useState("添加");
  const [RowData, setRowData] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (type: string, rows?: any) => {
    if (type == "edit") {
      setForm({ ID: rows.ID, title: rows.title, content: rows.content });
    }
    if (type == "add") setForm({ title: "", content: "", ID: "" });
    setType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setTitle(type == "add" ? "添加" : "编辑");
  }, [type]);

  const getPostAll = async (page: number, rowsPerPage: number) => {
    try {
      const res: any = await getPost(page, rowsPerPage);
      setRowData(res.data.map((i: any) => ({ ...i, Operator: "1" })));
      console.log("res.data: ", res.data);
      setTotal(res.total_records);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const addPostOne = async (input: any) => {
    const { title, content } = input;
    try {
      const res: any = await addPost({ title, content });
      console.log("res: ", res);
      handleClose();
      getPostAll(page, rowsPerPage);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const edit = async (input: any) => {
    const { ID, title, content } = input;
    try {
      const res: any = await UpdatePost(ID, { title, content });
      handleClose();
      getPostAll(page, rowsPerPage);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const deletePostOne = async (input: any) => {
    const { ID } = input;
    try {
      const res: any = await deletePost(ID);
      console.log("res: ", res);
      getPostAll(page, rowsPerPage);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  React.useEffect(() => {
    getPostAll(page, rowsPerPage);
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    getPostAll(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getPostAll(0, +event.target.value);
  };

  const transDate = (dates: string) => {
    if (!dates) return "";
    const date = new Date(dates); // 使用ISO格式字符串创建Date对象
    const formattedDate = date.toISOString().replace("T", " ").substring(0, 19); // 将ISO格式的时间转为YYYY-MM-DD hh:mm:ss格式
    return formattedDate;
  };

  const columns: readonly Column[] = [
    {
      id: "ID",
      label: "ID",
      minWidth: 60,
      format: (row: any) => {
        return (
          <div
            onClick={() => {
              copyToClipboard(row["ID"]);
            }}
          >
            {row["ID"]}
          </div>
        );
      },
    },
    {
      id: "title",
      label: "Title",
      minWidth: 170,
      format: (row: any) => {
        return (
          <div
            onClick={() => {
              copyToClipboard(row["title"]);
            }}
          >
            {row["title"]}
          </div>
        );
      },
    },
    { id: "content", label: "Content", minWidth: 250 },
    {
      id: "CreatedAt",
      label: "CreatedAt",
      minWidth: 120,
      format: (row: any) => transDate(row["CreatedAt"]),
    },
    {
      id: "UpdatedAt",
      label: "UpdatedAt",
      minWidth: 120,
      format: (row: any) => transDate(row["UpdatedAt"]),
    },
    {
      id: "Operator",
      label: "Operator",
      minWidth: 100,
      format: (column: any) => {
        console.log("column: ", column);
        return (
          <React.Fragment>
            <IconButton
              aria-label="edit"
              onClick={() => {
                handleClickOpen("edit", column);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                deletePostOne(column);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        );
      },
    },
  ];

  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const [form, setForm] = React.useState({ title: "", content: "", ID: "" });

  const onChange = (e: any, key: string) => {
    setForm((val) => {
      return {
        ...val,
        [key]: e.target.value,
      };
    });
  };
  const submit = () => {
    type == "add" ? addPostOne(form) : edit(form);
  };

  return (
    <Paper sx={{ width: "98%", overflow: "hidden", margin: "auto" }}>
      <div style={{ paddingLeft: "20px" }}>
        <IconButton
          aria-label="add"
          onClick={() => {
            window.location.href = "/";
          }}
          title="返回"
        >
          <BackIcon style={{ width: "2rem", height: "2rem" }} />
        </IconButton>
        <IconButton
          aria-label="add"
          onClick={() => {
            handleClickOpen("add");
          }}
          title="新增"
        >
          <AddIcon style={{ width: "2rem", height: "2rem" }} />
        </IconButton>
      </div>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ maxHeight: 20 }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {RowData.map((row: Data) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
                  {columns.map((column) => {
                    const value: any = row[column.id];
                    return (
                      <TableCell key={column.id} align={"center"}>
                        <div
                          style={{
                            maxHeight: "80px",
                            overflow: "auto",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {column.format ? column.format(row) : value}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            backgroundColor: "var(--white)",
            color: "var(--black)",
            borderBottom: "1px solid var(--second)",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "var(--white)" }}>
          <DialogContentText id="alert-dialog-description">
            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                // maxWidth='27rem'
                width="100%"
                sx={layoutStyles.formBox}
              >
                <FormInput
                  name="email"
                  label={"title"}
                  type="title"
                  value={form.title}
                  onChange={(e) => {
                    onChange(e, "title");
                  }}
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--black)",
                  }}
                />
                <FormText
                  name="content"
                  label={"content"}
                  type="text"
                  value={form.content}
                  onChange={(e) => {
                    onChange(e, "content");
                  }}
                />
                {form.content && (
                  <div
                    className="markdown-body"
                    style={{
                      background: "var(--white)",
                      padding: "1rem",
                      maxHeight: "400px",
                      overflow: "auto",
                      borderRadius: "1rem",
                      border: "1px solid var(--black)",
                      color: "var(--black)",
                    }}
                  >
                    <Markdown content={form.content} />
                  </div>
                )}
              </Box>
            </FormProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: "var(--white)",
            color: "var(--black)",
            borderTop: "1px solid var(--second)",
          }}
        >
          <Button onClick={handleClose}>取消</Button>
          <Button
            onClick={() => {
              submit();
            }}
            autoFocus
          >
            提交
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
