import { Box, Container, Typography, Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../components/FormInput";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useLoginUserMutation, refreshAuth } from "../redux/api/authApi";
import { ReactComponent as GoogleLogo } from "../assets/google.svg";
import { ReactComponent as GitHubLogo } from "../assets/github.svg";
import { getMe } from "../requests";
import { getGoogleUrl } from "../utils/getGoogleUrl";
import { getGitHubUrl } from "../utils/getGithubUrl";
import { setCookie, getCookie } from "../utils/cookie";
import {
  Message,
  SubmitKey,
  LOCAL_KEY,
  useUserStore,
  getChatStore,
  useAccessStore,
  ChatSession,
} from "../store";
import Locale from "../locales";
import * as layoutStyles from "./styles";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: var(--primary);
  font-weight: 500;

  &:hover {
    background-color: var(--primary);
    transform: translateY(-2px);
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const loginSchema = object({
  email: string().min(1, "需要填写邮箱").email("邮箱地址有误"),
  password: string()
    .min(1, "需要填入密码")
    .min(8, "密码长于八个字符")
    .max(32, "密码要短于三十二个字符"),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const LoginPage = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  // 👇 API Login Mutation
  const [loginUser, { isLoading, isError, data, error, isSuccess }] =
    useLoginUserMutation();

  const [updateUser, setIsFirstLogin] = useUserStore((state: any) => [
    state.updateUser,
    state.setIsFirstLogin,
  ]);

  // const setUser = ChatStore((state: any) => state.setUser);

  const navigate = useNavigate();
  const location = useLocation();

  const user: any = useUserStore((state: any) => state.user);

  let ChatStore = getChatStore(user.email || "");

  const [
    createNewSession,
    getIndexSession,
    onGetSession,
    config,
    updateConfig,
  ] = ChatStore((state: any) => [
    state.newSession,
    state.getIndexSession,
    state.onGetSession,
    state.config,
    state.updateConfig,
  ]);

  const accessStore = useAccessStore();

  const getUser = async () => {
    const { data } = await getMe();
    accessStore.updateUser({
      ...data?.user,
    });
    if (
      !config.modelConfig?.max_timeout ||
      config.modelConfig.max_timeout < 20
    ) {
      updateConfig((config: any) => (config.modelConfig.max_timeout = 20));
    }

    updateUser(data?.user);
    setIsFirstLogin(true);
    setTimeout(() => {
      const currentSessions: any = getIndexSession(0);
      if (currentSessions.messages.length > 1) {
        createNewSession();
      }
    }, 1000);
  };

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("You successfully logged in");
      if (data && data?.status === "success") {
        // onGetSession();
        setCookie("mojolicious", data?.access_token, 3000000);
        getUser();
        navigate(from);
        // startTokenTimer()
      } else {
        toast.error("账号或密码错误", {
          position: "top-right",
        });
      }
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    loginUser(values);
  };

  return (
    <Container maxWidth={false} sx={layoutStyles.container}>
      <Box sx={layoutStyles.box}>
        <Typography
          textAlign="center"
          component="h1"
          sx={layoutStyles.TypographySm}
        >
          {Locale.LayoutInfo.Welcome}
        </Typography>
        <Typography variant="body1" component="h2" sx={{ mb: 2 }}>
          {Locale.LayoutInfo.loginDescription}
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="27rem"
            width="100%"
            sx={layoutStyles.formBox}
          >
            <FormInput
              name="email"
              label={Locale.LayoutInfo.email}
              type="email"
            />
            <FormInput
              name="password"
              label={Locale.LayoutInfo.password}
              type="password"
            />

            <Typography
              sx={{ fontSize: "0.9rem", mb: "1rem", textAlign: "right" }}
            >
              <LinkItem
                to="/forgotpassword"
                style={{ color: "var(--primary)" }}
              >
                {Locale.LayoutInfo.forgetPassword}
              </LinkItem>
            </Typography>

            <LoadingButton
              variant="contained"
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              {Locale.LayoutInfo.login}
            </LoadingButton>

            <Typography sx={{ fontSize: "0.9rem", mt: "1rem" }}>
              {Locale.LayoutInfo.needCount}{" "}
              <LinkItem to="/register" style={{ color: "var(--primary)" }}>
                {Locale.LayoutInfo.clickToRegister}
              </LinkItem>
            </Typography>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default LoginPage;
