import { filterConfig, Message, ModelConfig } from "./store";
import Locale from "./locales";
import { getCookie, deleteCookie } from "./utils/cookie";
import { toast } from "react-toastify";
import { convertToPenny, sortByUpdatedAt } from "./utils";
import { refreshAuth } from "./redux/api/authApi";
import { useUserStore } from "./store/user";
import { getChatStore } from "./store/app";

const { user, updateTip } = useUserStore.getState();

// const TIME_OUT_MS = 20000;

const getTimeOutMs = (timeout: any) => {
  if (timeout && timeout > 20) return timeout;
  return 20;
};

const makeRequestParam = (
  messages: Message[],
  options?: {
    filterBot?: boolean;
    stream?: boolean;
  }
): any => {
  let sendMessages = messages.map((v) => ({
    role: v.role,
    content: v.content,
  }));

  if (options?.filterBot) {
    sendMessages = sendMessages.filter((m) => m.role !== "assistant");
  }

  return {
    model: "gpt-3.5-turbo-0301",
    messages: sendMessages,
    stream: options?.stream,
  };
};

function getHeaders() {
  let headers: Record<string, string> = {};
  // if (getCookie("mojolicious")) {
  //     headers["Authorization"] = "Bearer "+ getCookie("mojolicious"); // 请求头携带 token
  // }
  return headers;
}

export async function requestChat(messages: Message[]) {
  const req: any = makeRequestParam(messages, { filterBot: true });

  const res = await fetch(`/api/chat/completion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
    body: JSON.stringify(req),
  });

  return (await res.json()) as any;
}

export async function updateBalance(req: { email: string; amount: string }) {
  await refreshAuth();
  const res = await fetch(`/api/users/admin_update_balance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
    body: JSON.stringify({
      ...req,
      amount: convertToPenny(Number(req.amount)),
    }),
  });

  return (await res.json()) as any;
}

export async function getMe() {
  await refreshAuth();
  const res = await fetch(`/api/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
  });

  if (res.ok) {
    return (await res.json()) as any;
  } else if (res.status === 401) {
    // deleteCookie("mojolicious");
    deleteCookie("mojolicious");
    toast.error("登录过期，请重新登录", {
      position: "top-right",
    });
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }
}

export async function requestChatStream(
  messages: Message[],
  options?: {
    filterBot?: boolean;
    modelConfig?: ModelConfig;
    stream?: boolean;
    onMessage: (message: string, done: boolean, balance?: string) => void;
    onError: (error?: Error) => void;
    onController?: (controller: AbortController) => void;
    setShowModal?: (isShow: boolean) => void;
    onIsRequireComplete?: (isRequireComplete: boolean) => void;
  }
) {
  await refreshAuth();

  let ChatStore = getChatStore && getChatStore((user as any)?.email || "");
  const { onUpdateCountDown } = ChatStore.getState();

  onUpdateCountDown(true);

  const req = makeRequestParam(messages, {
    stream: options?.stream,
    filterBot: options?.filterBot,
  });

  // valid and assign model config
  if (options?.modelConfig) {
    Object.assign(req, filterConfig(options.modelConfig));
  }

  const TIME_OUT_MS = 10000;
  // getTimeOutMs((options as any).modelConfig?.max_timeout) * 1000;

  console.log("[Request] ", req);

  const regex = /\[TotalCredit:\s*\d+\]/g;
  let controller: any = null;
  let isCancel = false;
  let reqTimeoutId = setTimeout(() => {
    isCancel = true;
    controller && controller.abort();
  }, TIME_OUT_MS + 10000);

  const fetchFun = async (timeoutId: any) => {
    controller && controller.abort();
    controller = new AbortController();
    try {
      updateTip("");
      options?.onIsRequireComplete?.(true);
      const res = await fetch(`/api/chat/completion_with_model_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getHeaders(),
        },
        body: JSON.stringify(req),
        signal: controller.signal,
      });
      clearTimeout(reqTimeoutId);
      clearTimeout(timeoutId);

      let responseText = "";

      const finish = () => {
        const result: any = responseText.match(regex);
        let balance: any;
        if (result?.length) {
          balance = (result[0] as string).replace(/[^0-9]/g, "") || "0";
        }
        options?.onMessage(responseText.replace(regex, ""), true, balance);
        // controller.abort();
        options?.onIsRequireComplete?.(false);
      };

      if (res.ok) {
        clearTimeout(timeoutId);
        updateTip("");
        const reader = res.body?.getReader();
        const decoder = new TextDecoder();

        options?.onController?.(controller);

        while (true) {
          // handle time out, will stop if no response in 10 secs
          const resTimeoutId = setTimeout(() => finish(), TIME_OUT_MS);
          const content = await reader?.read();
          clearTimeout(resTimeoutId);

          let text: any = decoder.decode(content?.value);

          if (options?.stream == false && text) {
            const jsonData = JSON.parse(text);
            text =
              jsonData?.data?.reply +
                `[TotalCredit:${jsonData.data?.totalCredit}]` || "";
          }

          responseText += text;

          const done = !content || content.done || responseText.match(regex);

          const result = responseText.replace(regex, "");
          options?.onMessage(result, false);

          if (done) {
            options?.onIsRequireComplete?.(false);
            break;
          }
        }
        finish();
      } else if (res.status === 401) {
        const data = await res.json();
        options?.onError(data.message as Error);
        options?.onIsRequireComplete?.(false);
        clearTimeout(timeoutId);
        toast.error("Login has expired. Please log in again", {
          position: "top-right",
        });
        console.error("Anauthorized");
        // responseText = Locale.Error.Unauthorized;
        deleteCookie("mojolicious");
        finish();
        updateTip("请先登录");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (res.status === 400) {
        const data = await res.json();
        options?.onError(data.message as Error);
        clearTimeout(timeoutId);
        toast.error("余额不足，请先充值", {
          position: "top-right",
        });
        updateTip("余额不足，请先充值");
        (options as any).onIsRequireComplete(false);
        (options as any).setShowModal(true);
      } else {
        clearTimeout(timeoutId);
        const data = await res.json();
        options?.onError(data.message as Error);
        (options as any).onIsRequireComplete(false);
        if (data?.errorMsg.includes("This model's maximum")) {
          updateTip("内容长度超过限制，请删减内容或新建聊天。");
        } else {
          updateTip("高峰期，请稍后再试。");
        }
      }
    } catch (err) {
      (options as any).onIsRequireComplete(false);
      if (
        (err as Error).message.includes("The user aborted a request") &&
        isCancel
      ) {
        clearTimeout(timeoutId);
        options?.onError(err as Error);
        updateTip("高峰期，请稍后再试。");
      } else if (
        (err as Error).message.includes("The user aborted a request")
      ) {
      } else {
        clearTimeout(timeoutId);
        options?.onError(err as Error);
        updateTip("高峰期，请稍后再试。");
      }
    }
  };
  let timeoutId = setTimeout(() => {
    // 请求超时，取消当前请求并重新发送请求
    onUpdateCountDown(false);
    setTimeout(() => {
      fetchFun(timeoutId);
    }, 0);
  }, 10000);

  fetchFun(timeoutId);
}

export async function requestWithPrompt(messages: Message[], prompt: string) {
  await refreshAuth();
  messages = messages.concat([
    {
      role: "user",
      content: prompt,
      date: new Date().toLocaleString(),
    },
  ]);

  const res = await requestChat(messages);

  return res.choices[0]?.message?.content ?? "";
}

// To store message streaming controller
export const ControllerPool = {
  controllers: {} as Record<string, AbortController>,

  addController(
    sessionIndex: number,
    messageIndex: number,
    controller: AbortController
  ) {
    const key = this.key(sessionIndex, messageIndex);
    this.controllers[key] = controller;
    return key;
  },

  stop(sessionIndex: number, messageIndex: number) {
    const key = this.key(sessionIndex, messageIndex);
    const controller = this.controllers[key];
    console.log(controller);
    controller?.abort();
  },

  remove(sessionIndex: number, messageIndex: number) {
    const key = this.key(sessionIndex, messageIndex);
    delete this.controllers[key];
  },

  key(sessionIndex: number, messageIndex: number) {
    return `${sessionIndex},${messageIndex}`;
  },
};

export async function addPost(createPost: { title: string; content: string }) {
  await refreshAuth();
  const res = await fetch(`/api/posts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
    body: JSON.stringify(createPost),
  });

  return (await res.json()) as any;
}

export async function getPost(page: number, limit: number) {
  await refreshAuth();
  const res = await fetch(`/api/posts?page=${page + 1}&limit=${limit}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
  });

  return (await res.json()) as any;
}

export async function UpdatePost(
  postId: string,
  createPost: { title: string; content: string }
) {
  await refreshAuth();
  const res = await fetch(`/api/posts/${postId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
    body: JSON.stringify(createPost),
  });

  return (await res.json()) as any;
}

export async function deletePost(postId: string) {
  await refreshAuth();
  const res = await fetch(`/api/posts/${postId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
  });

  return res;
}

export async function getLatestPost() {
  await refreshAuth();
  const res = await fetch(`/api/posts/getLatestPost`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
  });

  return (await res.json()) as any;
}

export async function addConversationHistory(
  conversationHistory: {
    title: string;
    content: string;
  },
  options: {
    onCallBack: (id: string) => void;
    onMessage: (message: string) => void;
  }
) {
  await refreshAuth();
  try {
    const res: any = await fetch(`/api/conversationHistory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getHeaders(),
      },
      body: JSON.stringify(conversationHistory),
    });

    if (res.ok) {
      const { data } = await res.json();
      options.onCallBack(data.id);
      // return (await res.json()) as any;
    } else if (res.status === 403) {
      options.onMessage(res.message);
    }
  } catch (err: any) {
    toast.error(err.message || "云端数据保存错误", {
      position: "top-right",
    });
  }
}

export async function getConversationHistory(options: {
  onCallBack: (id: string) => void;
}) {
  await refreshAuth();
  try {
    const res = await fetch(`/api/conversationHistory`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getHeaders(),
      },
    });
    if (res.ok) {
      const { data } = await res.json();

      options.onCallBack(data.sort(sortByUpdatedAt));
      // return (await res.json()) as any;
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    toast.error(err.message || "云端数据获取错误", {
      position: "top-right",
    });
  }
}

export async function UpdateConversationHistory(
  conversationId: string,
  conversationHistory: { title: string; content: string },
  options: {
    onCallBack: (data: any) => void;
  }
) {
  try {
    await refreshAuth();
    const res = await fetch(`/api/conversationHistory/${conversationId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...getHeaders(),
      },
      body: JSON.stringify(conversationHistory),
    });
    if (res.ok) {
      const { data } = await res.json();
      options.onCallBack(data);
      // return (await res.json()) as any;
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    toast.error(err.message || "云端数据更新错误", {
      position: "top-right",
    });
  }
}

export async function deleteConversationHistory(
  conversationId: string,
  options: {
    onCallBack: (data: any) => void;
  }
) {
  try {
    await refreshAuth();
    const res = await fetch(`/api/conversationHistory/${conversationId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...getHeaders(),
      },
    });
    if (res.ok) {
      options.onCallBack(res);
      // return (await res.json()) as any;
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    toast.error(err.message || "云端数据删除错误", {
      position: "top-right",
    });
  }
}

export async function addRechargingCards(
  amount: number,
  balance_in_cents: number,
  options: {
    onCallBack: (data: any) => void;
  }
) {
  try {
    const res: any = await fetch(
      `/api/users/admin_bulk_create_recharging_cards`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getHeaders(),
        },
        body: JSON.stringify({ amount, balance_in_cents }),
      }
    );

    if (res.ok) {
      const { data } = await res.json();
      options.onCallBack(data);
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    toast.error(err.message || "生成充值卡错误", {
      position: "top-right",
    });
  }
}

export async function getRechargingCards(
  page: number,
  limit: number,
  options: {
    onCallBack: (data: string) => void;
  }
) {
  await refreshAuth();
  try {
    const res = await fetch(
      `/api/users/admin_get_recharging_cards?page=${page + 1}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...getHeaders(),
        },
      }
    );
    if (res.ok) {
      options.onCallBack(await res.json());
      // return (await res.json()) as any;
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    toast.error(err.message || "获取数据错误", {
      position: "top-right",
    });
  }
}

export async function deleteRechargingCards(
  rechargingCardId: string,
  options: {
    onCallBack: (data: any) => void;
  }
) {
  try {
    await refreshAuth();
    const res = await fetch(
      `/api/users/admin_deactivate_recharging_cards/${rechargingCardId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...getHeaders(),
        },
      }
    );
    if (res.ok) {
      options.onCallBack(res);
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    toast.error(err.message || "deactivate错误", {
      position: "top-right",
    });
  }
}

export async function rechargeRechargingCards(
  card_id: number,
  password: number,
  options: {
    onCallBack: (data: any) => void;
  }
) {
  try {
    const res: any = await fetch(`/api/users/recharge_with_recharging_card`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getHeaders(),
      },
      body: JSON.stringify({ card_id, password }),
    });

    if (res.ok) {
      const { data } = await res.json();
      options.onCallBack(data);
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err: any) {
    if (err.message.includes("invalid UUID length")) {
      toast.error("无效的充值卡信息!", {
        position: "top-right",
      });
    } else if (err.message.includes("Failed to recharge")) {
      toast.error("充值失败，充值卡已使用或密码错误！", {
        position: "top-right",
      });
    } else {
      toast.error(err.message || "充值卡充值错误，请重试", {
        position: "top-right",
      });
    }
  }
}
